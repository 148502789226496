import get from 'lodash/get';
import set from 'lodash/set';

const sudoOperationNames = [
  'updateAccount',
  'deactivateAccount',
  'updateBusiness',
  'removeBusiness',
];

export const KNOWN_ERRORS = {
  NO_CREDENTIALS: 'no_credentials',
  NO_PLACES: 'no_places',
  NOT_SUBSCRIBED: 'not_subscribed',
  CLOSED_BUSINESS: 'closed_business',
  TAX_EXEMPT_BUSINESS: 'tax_exempt_business',
  NO_PASSWORD: 'no_password',
  MARKET_TOO_SMALL: 'market_too_small',
  NOT_REDEEMABLE: 'not_redeemable',
  NOT_ACCEPTABLE: 'not_acceptable',
  NOT_AUTHORIZED: 'not_authorized',
};

export function hasErrorWithCode(error, code) {
  if (!error) {
    return false;
  }

  const errors = Array.isArray(error) ? error : error.graphQLErrors;
  return errors.some((e) => e.extensions && e.extensions.code === code);
}

export function sudoExpiredError(errors) {
  if (!errors || errors.length === 0) {
    return false;
  }

  const matched = errors[0].message.match(
    /'(.+)' doesn't exist on type 'Mutation'/
  );

  if (!matched) {
    return false;
  }

  return sudoOperationNames.includes(matched[1]);
}

export function fieldNotFoundError(errors) {
  if (!errors || errors.length === 0) {
    return false;
  }

  return !!errors[0].message.match(/doesn't exist on type/);
}

export function businessNotFoundError(errors) {
  if (!errors || errors.length === 0) {
    return false;
  }

  return !!errors[0].message.match(/Could not resolve to a Business/);
}

export function knownSnowdonError(errors) {
  if (!errors) {
    return false;
  }

  return errors.some((e) =>
    Object.values(KNOWN_ERRORS).includes(e.extensions?.code)
  );
}

export function knownPhoenixError(errors, operation) {
  if (
    !errors ||
    errors.length === 0 ||
    operation.getContext().target !== 'PHOENIX'
  ) {
    return false;
  }

  switch (errors[0]?.errorType) {
    case 'BankingError':
    case 'InvalidError':
    case 'KakaoApiError':
      return true;

    default:
      return false;
  }
}

export function extractErrors(data, path = 'response') {
  if (!data) {
    return {};
  }

  const dataPath = get(data, path);
  if (!dataPath) {
    throw Error(
      `extractErrors: path "${path}" is not included in ${JSON.stringify(
        data
      )}.`
    );
  }

  if (!dataPath.errors) {
    return {};
  }

  return dataPath.errors.reduce((acc, error) => {
    acc[error.field] = error.messages[0];
    return acc;
  }, {});
}

export function extractEdgeNodes(connection) {
  if (!connection || !connection.edges) {
    return [];
  }

  return connection.edges
    .map(({ node }) => node)
    .filter((node) => node !== null);
}

// TODO(sanghee): extractEdgeNodes edges 대신에 nodes 를 바로 가져오는 상황이.
// edges 를 제외하고 바로 nodes 를 가져오게 함. 쿼리를 바꾸던가 이쪽을 refactor 해야
// 하는 상황.
export function extractNodes(connection) {
  if (!connection || !connection.nodes) {
    return [];
  }

  return connection.nodes.filter((node) => node !== null);
}

export function hasNextPage(connection) {
  if (!connection || !connection.pageInfo) {
    return false;
  }

  return connection.pageInfo.hasNextPage;
}

export function loadMore(data, path, fetchMore, cursorKey = 'cursor') {
  const dataPath = get(data, path);
  if (!dataPath) {
    throw Error(
      `loadMore: path "${path}" is not included in ${JSON.stringify(data)}.`
    );
  }

  return fetchMore({
    variables: {
      [cursorKey]: dataPath.pageInfo.endCursor,
      isFetchMore: true,
    },
    updateQuery(previousResult, { fetchMoreResult }) {
      const { edges: previousEdges } = get(previousResult, path);
      const { edges } = get(fetchMoreResult, path);

      return set(fetchMoreResult, `${path}.edges`, [
        ...previousEdges,
        ...edges,
      ]);
    },
  }).catch((e) => {
    if (!/^ObservableQuery with this id doesn't exist:/.test(e.message)) {
      throw e;
    }
  });
}

// TODO(sanghee): loadMore에서 edges 대신에 nodes 를 바로 가져오는 상황이 생겨서
// edges 를 제외하고 바로 nodes 를 가져오게 함. 쿼리를 바꾸던가 이쪽을 refactor 해야
// 하는 상황
export function loadMoreNodes(data, path, fetchMore, cursorKey = 'cursor') {
  const dataPath = get(data, path);
  if (!dataPath) {
    throw Error(
      `loadMore: path "${path}" is not included in ${JSON.stringify(data)}.`
    );
  }

  return fetchMore({
    variables: {
      [cursorKey]: dataPath.pageInfo.endCursor,
      isFetchMore: true,
    },
    updateQuery(previousResult, { fetchMoreResult }) {
      const { nodes: previousNodes } = get(previousResult, path);
      const { nodes } = get(fetchMoreResult, path);

      return set(fetchMoreResult, `${path}.nodes`, [
        ...previousNodes,
        ...nodes,
      ]);
    },
  }).catch((e) => {
    if (!/^ObservableQuery with this id doesn't exist:/.test(e.message)) {
      throw e;
    }
  });
}

const CUSTOM_KEY_FOR_TYPENAMES = {
  Acceptance: ['terms'],
  NotificationPreference: ['type'],
  CardMerchant: ['issuer', 'merchantNumber'],
  TaxAccountant: ['phoneNumber'],
};

export function customCacheKey(object) {
  const keys = CUSTOM_KEY_FOR_TYPENAMES[object.__typename];
  if (!keys) {
    return null;
  }

  if (keys.some((key) => object[key] === null)) {
    return null;
  }

  const matched = window.location.pathname.match(/businesses\/(\d+)/);
  if (!matched) {
    return null;
  }

  const businessId = matched[1];
  const key = keys.map((key) => object[key]).join(':');

  return `$Business:${businessId}.${object.__typename}:${key}`;
}

export function extractFormErrorsInResult(data, typeName) {
  if (!data || data.result.__typename !== typeName) {
    return {};
  }

  const { __typename, ...errors } = data.result;

  return Object.keys(errors).reduce((result, key) => {
    result[key] = Array.isArray(errors[key]) ? errors[key][0] : null;
    return result;
  }, {});
}
