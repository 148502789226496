import get from 'lodash/get';
import pick from 'lodash/pick';

import {
  isIosAppWebView,
  isAndroidAppWebView,
  isAppWebView,
  isAppV2,
} from 'utils/userAgent';
import { track } from 'utils/trackEvent';

import type { ValuesType } from 'utility-types';
import { closeCurrentModal } from 'merlin/utils/appWebView';
import { logMessage } from 'utils/errorLogger';

const HANDLER_NAMES = {
  CHANGE_FOOTER_MENU: 'changeFooterMenuHandler',
  RELOAD: 'reloadHandler',
  TRACK_ANALYTICS_EVENT: 'trackAnalyticsEventHandler',
  LOAD_CERTIFICATE: 'loadCertificateHandler',
  LOAD_CERTIFICATE_ANY: 'loadCertificateAnyHandler',
  CONNECT_ALL_CARD_FRANCHISES: 'loadConnectAllCardFanchisesHandler',
  CONNECT_CARD_FRANCHISE: 'loadConnectCardFanchiseHandler',
  RECEIVE_RECOMMENDED_IDS_FOR_CARD_FRANCHISES:
    'receiveRecommendedIdsForCardFranchisesHandler',
  HIDE_SKELETON: 'hideSkeletonHandler',
  OPEN_MODAL: 'openModalHandler',
  OPEN_EXTERNAL_BROWSER_LINK: 'openExternalBrowserLinkHandler',
  KAKAO_LOGIN: 'loginWithKakaoHandler',
  START_BANKING_AUTH: 'startBankingAuthHandler',
  ASK_PASSCODE_TO_UNLOCK: 'askPasscodeToUnlockHandler',
  ASK_PASSCODE_TO_REGISTER_BANK_ACCOUNT:
    'askPasscodeToRegisterBankAccountHandler',
  ASK_PASSCODE_TO_TRANSFER_MONEY: 'askPasscodeToTransferMoneyHandler',
  OPEN_APP_SECURITY_PREFERENCES: 'openAppSecurityPreferencesHandler',
  CONFIRM_PASSCODE_RESET_TOKEN: 'confirmPasscodeResetTokenHandler',
  CLOSE_WINDOW: 'closeWindowHandler',
  ASK_PASSCODE_RESET: 'askPasscodeResetHandler',
  OPEN_USER_CERT: 'openUserCertHandler',
  LOGOUT: 'logoutHandler',
  ASK_PIN_NUMBER: 'askPinNumberHandler',
};

type HandlerName = ValuesType<typeof HANDLER_NAMES>;

function messageHandler(handlerName: HandlerName) {
  if (!handlerName) {
    logMessage(
      `messageHandler: Invalid handler name ${handlerName} is provided.`
    );
    return null;
  }

  if (isIosAppWebView()) {
    const handler = get(window, `webkit.messageHandlers.${handlerName}`);

    return handler ? (message?: string) => handler.postMessage(message) : null;
  } else if (isAndroidAppWebView()) {
    const androidInterface = get(window, 'CashnoteAndroidInterface');

    return androidInterface && androidInterface[handlerName]
      ? (message?: string) => androidInterface[handlerName](message)
      : null;
  }

  return null;
}

const createSimpleMessageHandler = <T extends any>(
  handlerName: HandlerName,
  keys?: string[]
) => (handlerPayload?: T) => {
  // note: 'keys' argument is not needed when the codebase is totally switched to TS
  const handler = messageHandler(handlerName);

  if (!handler) {
    return;
  }

  const payload =
    handlerPayload && keys
      ? JSON.stringify(pick(handlerPayload, keys))
      : undefined;

  handler(payload);
};

interface MessageData {
  invoked: string;
  success: boolean;
  result: {
    accountId?: string;
    [key: string]: any;
  };
  errors?: string[];
}

// 'HANDLER_NOT_REGISTERED': handler name is not registered.
// 'SIMULTANEOUS_INVOCATION': can't invoke handler simultaneously.

const createAsyncMessageHandler = <T extends any>(
  handlerName: HandlerName,
  options = {
    ALLOW_SIMULTANEOUS_INVOCATION: false,
  }
) => {
  let called = false;

  return (payload?: T) => {
    if (called && !options.ALLOW_SIMULTANEOUS_INVOCATION) {
      return Promise.reject({ reason: 'SIMULTANEOUS_INVOCATION' });
    }

    const handler = messageHandler(handlerName);
    const eventName = 'message';

    const meta: { [key: string]: any } = {
      payload: payload ? JSON.stringify(payload) : null,
      handlerName,
    };

    if (!handler) {
      called = false;

      alert('캐시노트 앱을 최신 버전으로 업데이트해주세요.');

      return Promise.reject({ reason: 'HANDLER_NOT_REGISTERED', meta });
    }

    called = true;

    return new Promise<MessageData>((res, rej) => {
      function messageCallback(event: MessageEvent) {
        meta.eventOrigin = event.origin;
        meta.source = event.source;

        try {
          meta.data = event.data;
          let data;
          try {
            data = JSON.parse(event.data) as MessageData;
          } catch (error) {
            logMessage('Error metadata', meta);
            return;
          }

          if (data.invoked === handlerName) {
            detachEvent();

            if (data.success === true) {
              return res(data);
            } else {
              return rej({
                reason: data.errors?.join(',') ?? 'UNKNOWN_ERROR',
                errors: data.errors ?? [],
                meta,
              });
            }
          }
        } catch (error) {
          return rej({
            reason: 'UNKNOWN_ERROR',
            errors: [error.name, error.stack ?? ''],
            meta,
          });
        }
      }

      function detachEvent() {
        window.removeEventListener(eventName, messageCallback);
      }

      window.addEventListener(eventName, messageCallback);

      handler(payload ? JSON.stringify(payload) : undefined);
    }).finally(() => {
      called = false;
    });
  };
};

export const changeFooterMenu = createSimpleMessageHandler<{
  from: string | null;
  to: string;
  path: string;
  type: string;
}>(HANDLER_NAMES.CHANGE_FOOTER_MENU, ['from', 'to', 'path', 'type']);

export const RELOAD_APP_RESET_POLICY = {
  RESET_APOLLO_CACHE: 'reset-apollo-cache',
  RELOAD_PAGE: 'reload-page',
};

export function reloadApp({
  tabs = true,
  account = false,
  businesses = false,
  nextBusinessId = null,
  nextTab = null,
  nextPath = null,
  resetPolicy = RELOAD_APP_RESET_POLICY.RESET_APOLLO_CACHE,
}: {
  tabs?: boolean;
  account?: boolean;
  businesses?: boolean;
  nextBusinessId?: string | null;
  nextTab?: string | null;
  nextPath?: string | null;
  resetPolicy?: ValuesType<typeof RELOAD_APP_RESET_POLICY>;
} = {}) {
  const reloadHandler = messageHandler(HANDLER_NAMES.RELOAD);
  if (!reloadHandler) {
    return;
  }

  const message = JSON.stringify({
    tabs,
    account,
    businesses,
    nextBusinessId,
    nextTab,
    nextPath,
    resetPolicy,
  });
  reloadHandler(message);
}

export function trackAnalyticsEvent(eventName: string, payload: Object) {
  track(eventName, payload);

  const handler = messageHandler(HANDLER_NAMES.TRACK_ANALYTICS_EVENT);
  if (!handler) {
    return;
  }

  const message = JSON.stringify({
    eventName,
    ...payload,
  });

  handler(message);
}

export function loadCertificate({
  credentialType,
  params = {},
  redirectAfter = null,
}: {
  credentialType: string;
  params?: Object;
  redirectAfter?: string | null;
}) {
  const handler = messageHandler(HANDLER_NAMES.LOAD_CERTIFICATE);
  if (!handler) {
    return;
  }

  const message = JSON.stringify({
    credentialType,
    params,
    redirectAfter,
  });

  handler(message);
}

export function loadCertificateAny({
  purpose,
  payload = {},
  redirectAfter,
}: {
  purpose: string;
  payload?: Object;
  redirectAfter?: string;
}) {
  const handler = messageHandler(HANDLER_NAMES.LOAD_CERTIFICATE_ANY);
  if (!handler) {
    return;
  }

  const message = JSON.stringify({
    purpose,
    payload,
    redirectAfter,
  });

  handler(message);
}

export const connectAllCardFranchises = createSimpleMessageHandler(
  HANDLER_NAMES.CONNECT_ALL_CARD_FRANCHISES
);

export function connectCardFranchise(issuer: string) {
  const handler = messageHandler(HANDLER_NAMES.CONNECT_CARD_FRANCHISE);
  if (!handler) {
    return;
  }

  handler(issuer);
}

export const receiveRecommendedIdsForCardFranchisesHandler = createSimpleMessageHandler(
  HANDLER_NAMES.RECEIVE_RECOMMENDED_IDS_FOR_CARD_FRANCHISES
);

export const hideAppWebViewSkeleton = createSimpleMessageHandler(
  HANDLER_NAMES.HIDE_SKELETON
);

export const openAppModal = createAsyncMessageHandler<{
  url: string;
}>(HANDLER_NAMES.OPEN_MODAL, {
  ALLOW_SIMULTANEOUS_INVOCATION: true,
});

export const openExternalBrowserLink = createSimpleMessageHandler<{
  url: string;
}>(HANDLER_NAMES.OPEN_EXTERNAL_BROWSER_LINK, ['url']);

export const loginWithKakao = createSimpleMessageHandler(
  HANDLER_NAMES.KAKAO_LOGIN
);

export const startBankingAuth = createAsyncMessageHandler(
  HANDLER_NAMES.START_BANKING_AUTH
);

export const askPasscodeToUnlock = createAsyncMessageHandler(
  HANDLER_NAMES.ASK_PASSCODE_TO_UNLOCK
);

export const askPasscodeToRegisterBankAccount = createAsyncMessageHandler<{
  accountId: string;
  businessId: string;
}>(HANDLER_NAMES.ASK_PASSCODE_TO_REGISTER_BANK_ACCOUNT);

export const askPasscodeToTransferMoney = createAsyncMessageHandler<{
  accountId: string;
  businessId: string;
  amount: string;
  destinationAccountNumber: string;
  destinationBankCode: string;
  recipientNote?: string;
  senderNote?: string;
}>(HANDLER_NAMES.ASK_PASSCODE_TO_TRANSFER_MONEY);

export const openAppSecurityPreferences = createSimpleMessageHandler(
  HANDLER_NAMES.OPEN_APP_SECURITY_PREFERENCES
);

export const confirmPasscodeResetToken = createSimpleMessageHandler<{
  accountId: string;
  token: string;
}>(HANDLER_NAMES.CONFIRM_PASSCODE_RESET_TOKEN, ['accountId', 'token']);

export const closeWindow = () => {
  if (isAppV2()) {
    closeCurrentModal();
    return;
  }

  const handler = messageHandler(HANDLER_NAMES.CLOSE_WINDOW);
  if (!handler) {
    if (isAppWebView()) {
      // window.close() does not work on some old versions
      const reloadHandler = messageHandler(HANDLER_NAMES.RELOAD);

      // @ts-ignore
      reloadHandler(
        JSON.stringify({
          tabs: false,
          account: false,
          businesses: false,
          nextBusinessId: null,
          nextTab: null,
          nextPath: null,
          resetPolicy: RELOAD_APP_RESET_POLICY.RESET_APOLLO_CACHE,
        })
      );
    } else {
      window.close();
    }

    return;
  }

  handler();
};

export const askPasscodeReset = createSimpleMessageHandler(
  HANDLER_NAMES.ASK_PASSCODE_RESET
);

export const openUserCert = createAsyncMessageHandler(
  HANDLER_NAMES.OPEN_USER_CERT
);

export const logout = createSimpleMessageHandler(HANDLER_NAMES.LOGOUT);

export const askPinNumber = createAsyncMessageHandler<{
  length: number;
  message: string;
  type: string;
  clientId: string;
}>(HANDLER_NAMES.ASK_PIN_NUMBER);
