import React, { useEffect } from 'react';
import { Route as ReactRoute } from 'react-router-dom';
import type { RouteProps as ReactRouteProps } from 'react-router-dom';

import { trackPageView } from 'utils/trackEvent';

export interface RouteProps extends ReactRouteProps {
  pageName?: string;
}

const Route: React.FC<RouteProps> = ({ pageName, ...rest }) => {
  useEffect(() => {
    if (pageName) {
      trackPageView(pageName);
    }
  }, [pageName]);

  return <ReactRoute {...rest} />;
};

export default Route;
