import storage from 'local-storage-fallback';
import { useCallback } from 'react';

export const STORAGE_ITEMS = {
  SERVICE_UNAVAILABLE_NOTICE_POPUP_SEEN_AT:
    'ServiceUnavailableNoticePopupSeenAt',
  NOTIFICATION_LAST_CHECKED_AT: 'notificationLastCheckedAt',
  HOME_REALTIME_SALES_CAMPAIGN_SEEN_AT: 'homeRealtimeSalesCampaignSeenAt',
  REPORT_REALTIME_SALES_CAMPAIGN_SEEN_AT: 'reportRealtimeSalesCampaignSeenAt',
  REPORT_REALTIME_OPEN_APP_CAMPAIGN_SEEN_AT:
    'reportRealtimeOpenAppCampaignSeenAt',
  APP_CAMPAIGN_SEEN_AT: 'appCampaignSeenAt',
  SALES_DOCUMENTATION_REQUESTED_AT: 'salesDocumentationRequestedAt',
  상권현황_지역_업종: 'regionClassificationFilter',
  홈_포스분석_배너: 'homePosDashboardBanner',
  TEMP_CARD_FRANCHISE_NOTICE: 'tempCardFranchiseNotice',
  AGREEMENT_ON_BC_BOTTOMSHEET_SEEN_AT: 'agreementOnBCSeenAt',
  사장님_비서_리다이렉트: 'ownerSecretaryRedirect',
  HOME_MMP_NOTICE_CREATIVE: 'homeMMPNoticeCreative',
  리뷰_관리_수집지연__NOTICE_MODAL: 'placeReviewsDelayNoticeModal',
  리뷰_관리_업데이트__BOTTOM_SHEET: 'placeReviewsUpdateBottomSheet',
  직접입력_오픈_가이드_팝업_그만보기: 'stopShowGuidePopup/customTransaction',
  PLATFORM: 'platform',
  VENDOR: 'vendor',
} as const;

type StorageItemKey = typeof STORAGE_ITEMS[keyof typeof STORAGE_ITEMS];

function getItemSafely(key: StorageItemKey): any | null {
  const storageItem = storage.getItem(key);
  if (storageItem === null) {
    return null;
  }

  try {
    return JSON.parse(storageItem);
  } catch (e) {
    return null;
  }
}

function useStorage(
  key: StorageItemKey
): [any | null, (value: any) => any | null, () => void] {
  const setItem = useCallback(
    (value: any) => {
      const lastValue = getItemSafely(key);
      storage.setItem(key, JSON.stringify(value));

      return lastValue;
    },
    [key]
  );

  const removeItem = useCallback(() => {
    storage.removeItem(key);
  }, [key]);

  return [getItemSafely(key), setItem, removeItem];
}

export default useStorage;
