import { createBrowserHistory, History } from 'history';

import { changeFooterMenu } from 'utils/appWebView';
import { isAppV2, isAppWebView } from 'utils/userAgent';
import { activeMenuNameByPath } from 'utils/activeMenu';
import { openModal, convertToV2Url } from 'merlin/utils/appWebView';
import { logMessage } from 'utils/errorLogger';

const history = createBrowserHistory();

const force = {
  push: history.push,
  replace: history.replace,
};

window.CashnoteHistoryChange = (type, path) => {
  if (type === 'push' || type === 'replace') {
    const forceHistory = force[type];
    forceHistory(path);
    return true;
  }

  logMessage(
    `window.CashnoteHistoryChage: Invalid type ${type} is provided. Should be one of ['push', 'replace'].`
  );
  return false;
};

export function withCashnoteHistoryChange(history: History): History {
  function adjust(fn: any, type: 'push' | 'replace') {
    return function interceptor() {
      const target = arguments[0];

      if (typeof target === 'string') {
        const converted = convertToV2Url(target);
        if (converted) {
          openModal(converted);
          return;
        }
      } else if (target.pathname) {
        const converted = convertToV2Url(target.pathname, target.search);
        if (converted) {
          openModal(converted);
          return;
        }
      } else {
        // @ts-ignore
        return fn.apply(this, arguments);
      }

      if (type === 'push') {
        // @ts-ignore
        return fn.apply(this, [target, { type: 'PUSH' }]);
      } else {
        // @ts-ignore
        return fn.apply(this, [target, window.history.state?.state]);
      }
    };
  }

  if (isAppV2()) {
    history.push = adjust(history.push, 'push');
    history.replace = adjust(history.replace, 'replace');
    return history;
  }

  if (!isAppWebView()) {
    return history;
  }

  function interceptHistory(fn: any, type: 'push' | 'replace') {
    return function interceptor() {
      const historyState = arguments[0];
      const toPathname =
        typeof historyState === 'string'
          ? historyState.split('?')[0]
          : historyState.pathname;

      if (toPathname) {
        const fromMenu = activeMenuNameByPath(history.location.pathname);
        const toMenu = activeMenuNameByPath(toPathname);

        if (fromMenu && toMenu && fromMenu !== toMenu) {
          changeFooterMenu({
            from: fromMenu,
            to: toMenu,
            path: historyState,
            type,
          });

          return;
        }
      }

      // @ts-ignore
      return fn.apply(this, arguments);
    };
  }

  history.push = interceptHistory(history.push, 'push');
  history.replace = interceptHistory(history.replace, 'replace');

  return history;
}

export default withCashnoteHistoryChange(history);
