import { matchPath } from 'react-router';

import { hasAppFeature } from 'utils/appWebView';

const MENU_SUB_PATHS = {
  finance: [
    'card-sales/payments',
    'card-sales/transactions',
    'invoices',
    'cash-receipts',
    'bank-transactions',
    'card-purchases',
    'delivery-app-sales',
    'vendors',
    'customers',
    'utility-bills',
    'tax-payments',
  ],
  insights: [
    'crosschecks',
    'card-sales/crosschecks',
    'unpaid-feature',
    'require-subscription',
    'tax-reductions',
    'tax-pricings',
    'card-merchants',
    'vat-reports',
    'card-fee-refunds',
    'invalid-invoices-reports',
    'invalid-invoices',
    'unpaid-invoices',
    'unreceived-invoices-reports',
    'unreceived-invoices',
    'receivable-tax-refunds',
    '프로모션',
    '신용점수조회',
  ],
  marketing: [
    'place-reviews',
    'quarterly-reports',
    'customer-reports',
    'market-reports',
  ],
  settings: [
    'detailed-settings',
    'edit',
    'delete',
    'integrations',
    'delivery-apps',
    'notifications',
    'members',
    'payment',
    'tax-accountants',
    'notification-feed',
    '페이노트-고객관리',
  ],
};

const UNDER_HOME_PATHS = ['notification-feed'];

const MOBILE_MODAL_PATHS = [
  'businesses/new',
  'account/edit',
  'account/invite',
  'account/deactivate',
  'account/retrieve',
  'intent/kakao/invite',
];

const isInMobileAppModal = (pathname: string) =>
  !!matchPath(pathname, {
    exact: true,
    path: MOBILE_MODAL_PATHS.map((path) => `/m/${path}`),
  });

const activeMenuNameByPath = (pathname: string) => {
  if (isInMobileAppModal(pathname)) {
    return null;
  }

  if (hasAppFeature('pagesUnderHomeTab')) {
    const matched = matchPath(pathname, {
      path: UNDER_HOME_PATHS.map((path) => `/m/businesses/:id/${path}`),
    });

    if (matched) {
      return 'home';
    }
  }

  const activeMenu = Object.entries(MENU_SUB_PATHS).find(([menu, subPaths]) =>
    matchPath(pathname, {
      path: [menu, ...subPaths].map((path) => `/m/businesses/:id/${path}`),
    })
  );

  return activeMenu ? activeMenu[0] : 'home';
};

const isInSettingsMenu = (pathname: string) =>
  activeMenuNameByPath(pathname) === 'settings';

export { activeMenuNameByPath, isInSettingsMenu };
