import Detect from 'mobile-detect';
import gte from 'semver/functions/gte';
import coerce from 'semver/functions/coerce';

import type { Platform } from 'types';

import { APP_NAME } from 'utils/appWebView/constants';

const UserAgent = new Detect(window.navigator.userAgent);
const KAKAO_SAFARI_MIN_SUPPORT_IOS_VERSION = 9;

export const platform = (): Platform => {
  if (isAndroidAppWebView()) {
    return 'android';
  } else if (isIosAppWebView()) {
    return 'ios';
  }

  return 'web';
};

export const deviceOS = () => {
  if (isAndroid()) {
    return 'android';
  } else if (isIos()) {
    return 'ios';
  }

  return 'desktop';
};

export const isWindows = () => window.navigator.userAgent.match(/windows/i);

export const isMobile = () => !!UserAgent.mobile();

export const isAppWebView = () => isIosAppWebView() || isAndroidAppWebView();

export const isAppV2 = () =>
  isEnoughForTargetAppVersion('2.0.0') || !isAppWebView();

export const isAppV1 = () => isAppWebView() && !isAppV2();

export const isIosAppWebView = () =>
  !!window.navigator.userAgent.match(new RegExp(APP_NAME.ios));

export const isAndroidAppWebView = () =>
  !!window.navigator.userAgent.match(new RegExp(APP_NAME.android));

export const isKakaoBrowser = () =>
  !!window.navigator.userAgent.match(/KAKAOTALK/);

export const isIos = () => UserAgent.is('iOS');

export const isAndroid = () => UserAgent.is('AndroidOS');

export const isAppSupportedOS = () => isIos() || isAndroid();

export const isKakaoSupported = () => {
  if (isIos()) {
    if (UserAgent.match('NAVER')) {
      return false;
    }

    if (UserAgent.is('Safari')) {
      return (
        UserAgent.version('Version') >= KAKAO_SAFARI_MIN_SUPPORT_IOS_VERSION
      );
    }

    return isKakaoBrowser();
  }
  return true;
};

export const appVersion = () => {
  if (!isAppWebView()) {
    return null;
  }

  const os = isAndroidAppWebView() ? 'android' : 'ios';

  const matched = window.navigator.userAgent.match(
    new RegExp(`${APP_NAME[os]}/(.+)`)
  );

  if (!matched) {
    return null;
  }

  return matched[1];
};

export const isEnoughForTargetAppVersion = (
  targetVersion: string,
  currentVersion: string | null = appVersion()
) => {
  if (!currentVersion) {
    return false;
  }

  if (targetVersion === 'always') {
    return true;
  }

  if (targetVersion === 'dev') {
    return currentVersion.includes('/dev');
  }

  const coerced = coerce(currentVersion);

  if (!coerced) {
    return false;
  }

  return gte(coerced, targetVersion);
};

export default UserAgent;
