import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import type { Business as BusinessType } from 'graphql/types';

import getBusiness from 'graphql/queries/getBusiness.graphql';

import { isMobile } from 'utils/userAgent';
import { formatRegistrationNumber } from 'utils/registrationNumber';
import paths from 'paths';
import useSearchParams from 'hooks/useSearchParams';
import { trackEvent } from '@kcd/event-tracker';
import { logMessage } from 'utils/errorLogger';

export interface Business extends BusinessType {
  ownedByCurrentUser: boolean;
  representedByCurrentUser: boolean;
  displayRegistrationNumber: string;
  coupangEatsBalance?: number | null;
}

interface BusinessContextProps {
  currentBusiness?: Business;
}

export const BusinessContext = React.createContext<BusinessContextProps>({});

interface BusinessProviderProps {
  businessId: string;
}

const loadingBusiness = {
  name: '--',
  registrationNumber: '0000000000',
  role: 'OWNER' as const,
  representativeRole: 'VERIFIED_OWNER' as const,
  hasSalesInvoices: false,
  ownedByCurrentUser: true,
  representedByCurrentUser: true,
  displayRegistrationNumber: '--',
};

export const BusinessProvider: React.FC<BusinessProviderProps> = ({
  businessId,
  children,
}) => {
  const params = useSearchParams();
  const [pollInterval, setPollInterval] = React.useState(() => {
    const retry = Array.isArray(params.retry)
      ? params.retry[0]
      : params.retry ?? '';
    return retry ? parseInt(retry, 10) || 1000 : 0;
  });
  const { loading, data } = useQuery<{ business: BusinessType }>(getBusiness, {
    variables: { businessId },
    pollInterval,
  });

  React.useEffect(() => {
    if (data) {
      setPollInterval(0); // data fetch에 성공하면 polling 중단
    }
  }, [data]);

  React.useEffect(() => {
    setTimeout(() => {
      setPollInterval(0); // 5분 경과 후 polling 중단
    }, 5 * 60 * 1000);
  });

  if (loading) {
    return (
      <BusinessContext.Provider
        value={{
          // @ts-ignore
          currentBusiness: {
            id: businessId,
            ...loadingBusiness,
          },
        }}
      >
        {children}
      </BusinessContext.Provider>
    );
  }

  if (!loading && !data) {
    if (!pollInterval) {
      const message = `사업장을 찾을 수 없습니다. location: ${
        window.location.href
      } / businessId: ${businessId} / fromFinanceBanner: ${Boolean(
        params.fromFinanceBanner
      )} `;
      logMessage(message);
      trackEvent('Kestrel businessId 디버깅', {
        message,
        fromFinanceBanner: params.fromFinanceBanner,
      });
      alert('사업장을 찾을 수 없습니다.');
      paths.replace(
        isMobile() ? paths.mobile.businesses.index() : paths.businesses.index()
      );
    }
    return null;
  }

  const {
    role,
    representativeRole,
    registrationNumber,
    representativeVerified,
  } = data!.business;

  return (
    <BusinessContext.Provider
      value={{
        currentBusiness: {
          ...data!.business,
          ownedByCurrentUser: role === 'OWNER',
          representativeVerified,
          representedByCurrentUser: representativeRole === 'VERIFIED_OWNER',
          displayRegistrationNumber: formatRegistrationNumber(
            registrationNumber
          ),
        },
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};
