import gql from 'graphql-tag.macro';

export default gql`
  fragment AccountInfo on User {
    id
    trackingId
    name
    realName
    email
    phoneNumber
    birthday
    identityVerified
  }
`;
