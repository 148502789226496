import React, { Component, useContext } from 'react';
import storage from 'local-storage-fallback';

import apolloClient from 'graphql/apolloClient';
import GET_ACCOUNT from 'graphql/queries/getAccount.graphql';
import UPDATE_ACCOUNT from 'graphql/mutations/updateAccount.graphql';
import DEACTIVATE_ACCOUNT from 'graphql/mutations/deactivateAccount.graphql';

import { extractErrors, sudoExpiredError } from 'utils/graphql';
import { reloadApp } from 'utils/appWebView';
import { track } from 'utils/trackEvent';

import paths from 'paths';
import t from 'i18n';
import { prefillZendeskWidget } from 'hooks/useZendesk';
import { hackleClient } from 'index';

export interface Account {
  id: string;
  trackingId: string;
  login: string;
  name: string;
  realName?: string;
  email: string;
  phoneNumber: string;
  hasPassword: string;
  hasCI: boolean;
  inviteCode: string;
  hasKakaoProfile: boolean;
  trialStartedAt: string;
  appLoggedIn: boolean;
  identityVerified: boolean;
  identityVerifiedAt: string | null;
  nationality: string | null;
  featureFlags: string[];
  referralCode: string;
  birthday?: string;
}

type NoopFn = () => void;
type LoadAccount = () => Promise<void>;
type UpdateAccount = (arg: {
  name: string;
  phoneNumber: string;
  token: string;
  password: string;
}) => Promise<void>;
type DeactivateAccount = (arg: { password: string }) => Promise<void>;

const AccountContext = React.createContext<{
  account: Account | null;
  accountErrors: any;
  loadAccount: NoopFn | LoadAccount;
  updateAccount: NoopFn | UpdateAccount;
  deactivateAccount: NoopFn | DeactivateAccount;
}>({
  account: null,
  accountErrors: {},
  loadAccount: () => {},
  updateAccount: () => {},
  deactivateAccount: () => {},
});
const { Provider, Consumer } = AccountContext;

type ProviderPropsType = any;
type ProviderStateType = {
  account: Account | null;
  accountErrors: any;
};

class AccountProvider extends Component<ProviderPropsType, ProviderStateType> {
  state = {
    account: null,
    accountErrors: {},
  } as ProviderStateType;

  componentDidMount() {
    window.ApolloClient.subscribeToResetStore('loadAccount', this.loadAccount);

    this.loadAccount();
  }

  componentDidUpdate(
    prevProps: ProviderPropsType,
    prevState: ProviderStateType
  ) {
    const { account: prevAccount } = prevState;
    const { account } = this.state;
    if (!account || prevAccount === account) {
      return;
    }

    const zendeskCallback = () => {
      if (!storage.getItem('token')) {
        return;
      }

      prefillZendeskWidget({
        name: { value: account.realName || account.name, readOnly: false },
        email: { value: account.email, readOnly: false },
      });
    };

    zendeskCallback();
    // TODO: account가 변경될 때마다 on:close가 교체되는 것이 아니라 추가되는 문제 해결
    window.zE?.('webWidget:on', 'close', zendeskCallback);
  }

  componentWillUnmount() {
    window.ApolloClient.unsubscribeFromResetStore('loadAccount');
    // TODO: on:close callback을 clear하지 못하는 문제 해결
    window.zE?.('webWidget', 'reset');
  }

  loadAccount = () => {
    return apolloClient
      .query({
        query: GET_ACCOUNT,
        fetchPolicy: 'network-only',
      })
      .then(({ data }: any) => {
        this.setState({ account: data.account, accountErrors: {} });
        hackleClient.setUser({
          id: data.account.trackingId,
          userId: data.account.id,
        });
      })
      .catch(() => {
        // do nothing
      });
  };

  updateAccount = ({ name, password }: { name: string; password: string }) => {
    return apolloClient
      .mutate({
        mutation: UPDATE_ACCOUNT,
        variables: {
          name,
          password,
        },
      })
      .then(({ data }: any) => {
        if (data.updateAccount.errors) {
          this.setState({
            accountErrors: extractErrors(data, 'updateAccount'),
          });
        } else {
          this.setState({
            account: data.updateAccount.account,
            accountErrors: {},
          });
          hackleClient.setUser({ userId: data.updateAccount.account.id });

          alert('회원 정보가 수정되었습니다.');

          reloadApp({ account: true });
        }

        return data;
      })
      .catch((e: any) => {
        if (!sudoExpiredError(e.graphQLErrors)) {
          alert(t('errors.messages.generic'));
        }
      });
  };

  deactivateAccount = ({ password }: { password: string }) => {
    return apolloClient
      .mutate({ mutation: DEACTIVATE_ACCOUNT, variables: { password } })
      .then(({ data }: any) => {
        if (data.deactivateAccount.errors) {
          this.setState({
            accountErrors: extractErrors(data, 'deactivateAccount'),
          });
        } else {
          track('회원 탈퇴');
          setTimeout(() => {
            paths.replace(paths.logout(true));
          }, 300);
        }
      })
      .catch((e: any) => {
        if (!sudoExpiredError(e.graphQLErrors)) {
          alert(t('errors.messages.generic'));
        }
      });
  };

  render() {
    if (!this.state.account) {
      return null;
    }

    return (
      <Provider
        value={{
          ...this.state,
          loadAccount: this.loadAccount,
          updateAccount: this.updateAccount,
          deactivateAccount: this.deactivateAccount,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

const withAccount = (Component: React.ElementType) => (
  props: React.Props<any>
) => (
  <Consumer>
    {({ account }) => <Component {...props} account={account} />}
  </Consumer>
);

const withAccountActions = (Component: React.ElementType) => (
  props: React.Props<any>
) => <Consumer>{(context) => <Component {...props} {...context} />}</Consumer>;

/**
 * @NOTE 이름 확인시 realName || name 순으로 참조 권장 (realName: 본인인증한 이름, name: 시기에 따라 카카오 닉네임과 섞여있음)
 */
const useAccount = (): Account => useContext(AccountContext).account as Account;

const useLoadAccount = () => useContext(AccountContext).loadAccount;

export {
  AccountContext,
  AccountProvider,
  Consumer as AccountConsumer,
  withAccount,
  withAccountActions,
  useAccount,
  useLoadAccount,
};
