import { parse as parseQueryString, stringify } from 'query-string';
import omit from 'lodash/omit';

import browserHistory from '../browserHistory';

import type { Params } from './paths';

const { location } = window;

export default {
  push: browserHistory.push,
  replace: browserHistory.replace,
  goBack: browserHistory.goBack,
  refreshWithoutQuery(
    removingQueries: string[] = [],
    method: 'push' | 'replace' = 'replace'
  ) {
    const nextQuery = stringify(
      omit(parseQueryString(location.search), removingQueries)
    );
    browserHistory[method](
      location.href.replace(location.search, '') + nextQuery
        ? `?${nextQuery}`
        : ''
    );
  },
  mergeSearchKey: ({ preserveScroll, ...rest }: Params) => {
    browserHistory.replace({
      search: stringify({
        ...parseQueryString(browserHistory.location.search),
        ...rest,
      }),
      state: { ...window.history.state?.state, preserveScroll },
    });
  },
  removeSearchKey: (key: string) => {
    browserHistory.replace({
      search: stringify({
        ...parseQueryString(browserHistory.location.search),
        [key]: undefined,
      }),
      state: { ...window.history.state?.state },
    });
  },
};
