import { stringify } from 'query-string';
import { isMobile } from 'utils/userAgent';

export type Params = { [key: string]: any };

function toString(query?: Params): string {
  return query ? `?${stringify(query)}` : '';
}

const KAKAO_ORDER_BASE_URL =
  process.env.REACT_APP_DEPLOY_ENV === 'production'
    ? 'https://order.kakao.com'
    : 'https://sandbox-order.devel.kakao.com';

const BLUEBIRD_BASE_URL =
  process.env.REACT_APP_DEPLOY_ENV === 'production'
    ? 'https://offline.cashnote.kr'
    : 'https://offline-staging.cashnote.kr';

const BIRCH_BASE_URL =
  process.env.REACT_APP_DEPLOY_ENV === 'production'
    ? 'https://birch.cashnote.kr'
    : 'https://birch-staging.cashnote.kr';

export default {
  external: {
    kakaoStore(storeCode: string) {
      return `${KAKAO_ORDER_BASE_URL}/store?storeCode=${storeCode}`;
    },
    map() {
      return 'https://map.cashnote.kr';
    },
    부가세: {
      신고매뉴얼(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/tax_content_pages/1?business_id=${businessId}`;
      },
      주요개정사항(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/tax_content_pages/9?business_id=${businessId}`;
      },
    },
    세무: {
      세무상담(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/tax_requests/new?consulting=true&business_id=${businessId}`;
      },
      견적요청(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/tax_requests/new?business_id=${businessId}`;
      },
    },
    매장관리(businessId: string) {
      return `${BLUEBIRD_BASE_URL}/alba_for_business?business_id=${businessId}`;
    },
    직원관리(businessId: string) {
      return `${BIRCH_BASE_URL}/staff-management/${businessId}`;
    },
    hometax: {
      help(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/acceptanced_business_templates/answer?code=348ff03cb2bbe4ed509c&business_id=${businessId}`;
      },
    },
    zendesk: {
      request() {
        return 'https://cashnote.zendesk.com/hc/ko/requests/new';
      },
      businessCard() {
        return 'https://cashnote.zendesk.com/hc/ko/articles/360045391431#article-container';
      },
      사장님_비서_소개() {
        return 'https://cashnote.zendesk.com/hc/ko/articles/360049069671#article-container';
      },
      premiumFeatures() {
        return 'https://cashnote.zendesk.com/hc/ko/articles/360003482251#article-container';
      },
      premiumFeaturesForAudit() {
        return 'https://cashnote.zendesk.com/hc/ko/articles/360050751832#article-container';
      },
    },
    taxReport: {
      surveyForm() {
        return 'https://cashnote.typeform.com/to/mTbeiG2i';
      },
    },
    노란우산공제: {
      homepage() {
        return 'https://www.8899.or.kr/yuma/index.do';
      },
      가입대상() {
        return 'https://yumam.kbiz.or.kr/yuma/contents/contents/contents.do?mnSeq=24';
      },
      제외업종() {
        return 'https://cashnote.kr/노란우산공제-가입-제외대상-업종';
      },
      센터안내연락처() {
        return 'https://cashnote.kr/소기업·소상공인공제-지역별-문의처';
      },
    },
    사장님_클래스: {
      index(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/academy?business_id=${businessId}`;
      },
      배달: {
        index(businessId: string) {
          return `${BLUEBIRD_BASE_URL}/academy?big_category_id=1&business_id=${businessId}`;
        },
        용기선택(businessId: string) {
          return `${BLUEBIRD_BASE_URL}/academy?category_id=3&business_id=${businessId}`;
        },
        앱내광고(businessId: string) {
          return `${BLUEBIRD_BASE_URL}/academy?category_id=14&business_id=${businessId}`;
        },
        앱비교(businessId: string) {
          return `${BLUEBIRD_BASE_URL}/academy?category_id=4&business_id=${businessId}`;
        },
      },
      세무: {
        index(businessId: string) {
          return `${BLUEBIRD_BASE_URL}/academy?big_category_id=2&business_id=${businessId}`;
        },
        비용처리(businessId: string) {
          return `${BLUEBIRD_BASE_URL}/academy?category_id=13&business_id=${businessId}`;
        },
        부가세(businessId: string) {
          return `${BLUEBIRD_BASE_URL}/academy?category_id=15&business_id=${businessId}`;
        },
      },
    },
    사장님_포럼() {
      return 'https://forum.cashnote.kr';
    },
    사장님_페이지(businessId: string) {
      return `${BLUEBIRD_BASE_URL}/page?business_id=${businessId}`;
    },
    배달_분석_리포트(businessId: string) {
      return `${BLUEBIRD_BASE_URL}/acceptanced_business_templates/answer?code=f0a7c8cb8fc2844e6ddf&business_id=${businessId}`;
    },
    렌탈: {
      에어드레서(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/acceptanced_business_templates/answer?code=489bd5c9dc5dfd98917d&business_id=${businessId}`;
      },
      공기청정기(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/acceptanced_business_templates/answer?code=3260e36a7dde86388878&business_id=${businessId}`;
      },
      식기세척기_렌탈서비스(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/rental?business_id=${businessId}`;
      },
    },
    사장님_비서: {
      금융상품: {
        BNK캐피탈() {
          return 'http://www.bnkcapital.co.kr/mobile/landing/cashnote.html';
        },
        피플펀드() {
          return 'https://www.peoplefund.co.kr/e/securedloan?dlpocode=cashnote&utm_source=mortgage_loan&utm_medium=cashnote&utm_campaign=secured&utm_content=living&utm_term=list&event_code=cashnote';
        },
        BC카드() {
          return 'https://u2.bccard.com/app/merapp/loanWS.do?exec=loanIntro&channel=cashnote';
        },
        롯데카드() {
          return 'https://m.lottecard.co.kr/app/LPFINBH_V100.lc?ccoChnlC=CN';
        },
        우대금리(businessId: string, utmSource: string) {
          return `${BLUEBIRD_BASE_URL}/finance_product_pages/6?utm_source=${utmSource}&business_id=${businessId}`;
        },
      },
    },
    포스: {
      매뉴얼() {
        return `${BLUEBIRD_BASE_URL}/files/pos_agents/pos_manual.pdf`;
      },
      설치_상담(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/acceptanced_business_templates/answer?code=d41154eac226d30939ec&business_id=${businessId}`;
      },
      고객관리_사전신청(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/acceptanced_business_templates/answer?code=8feca49d76e6a21f3f1d&business_id=${businessId}`;
      },
    },
    우동사() {
      return `${BLUEBIRD_BASE_URL}/community?utm_source=cashnote`;
    },
    프로모션: {
      우리은행_전용대출: {
        웹링크() {
          return 'https://m.wooribank.com/mw/mws?withyou=MWPRD0005&AR_CD=100#AR_CD=004&PLM_PDCD=P020006188&SEL_PRD_YN=N';
        },
        딥링크() {
          return 'https://smpib.wooribank.com/mpb/woori?withyou=NPCNT0091&target=/mpb/woori?withyou=NPCSA0095&PLM_PDCD=P020006188';
        },
      },
      신한카드() {
        return 'https://www.shinhancard.com/pconts/html/benefit/event/1197137_2239.html';
      },
      롯데카드(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/finance_product_pages/29?business_id=${businessId}`;
      },
      삼성카드() {
        return 'https://www.samsungcard.com/personal/event/ing/UHPPBE1403M0.jsp?cms_id=270508&click=out-cahsnote_banner_evt-270508';
      },
    },
    배너: {
      운영자금_긴급확보_상품(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/finance?business_id=${businessId}`;
      },
      사장님비서_지원이벤트(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/corona_support?business_id=${businessId}`;
      },
      폐업보장보험(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/finance_product_pages/14?business_id=${businessId}`;
      },
      보험지킴이서비스(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/finance_product_pages/4?business_id=${businessId}`;
      },
    },
    신용점수조회: {
      사장님점수_CONTENT(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/content_pages/70?business_id=${businessId}`;
      },
      코로나영업피해_CONTENT(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/content_pages/95?business_id=${businessId}`;
      },
      신용점수활용_CONTENT(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/content_pages/80?business_id=${businessId}`;
      },
      신용사면_CONTENT(businessId: string) {
        return `${BLUEBIRD_BASE_URL}/content_pages/3244?business_id=${businessId}`;
      },
    },
  },
  시작: {
    index(query = '') {
      return `/시작${query}`;
    },
  },
  login: {
    confirmation() {
      return '/login/confirmation';
    },
  },
  logout(deactivate?: boolean) {
    return `/logout${deactivate ? '?deactivate=true' : ''}`;
  },
  account: {
    retrieve() {
      return '/account/retrieve';
    },
    edit() {
      return '/account/edit';
    },
  },
  password: {
    reset() {
      return '/password/reset';
    },
  },
  businesses: {
    index() {
      return isMobile() ? '/m/businesses' : '/businesses';
    },
    사업장등록(query?: Params) {
      return `/사업장-등록${toString(query)}`;
    },
    edit(businessId: string) {
      return `/businesses/${businessId}/edit`;
    },
  },
  credentials: {
    index(businessId: string) {
      return `/businesses/${businessId}/credentials`;
    },
    certificates(businessId: string) {
      return `/businesses/${businessId}/credentials/certificates`;
    },
    bankAccounts(businessId: string) {
      return `/businesses/${businessId}/credentials/bank-accounts`;
    },
    hometax: {
      login(businessId: string) {
        return `/businesses/${businessId}/credentials/hometax/login`;
      },
      certificate(businessId: string) {
        return `/businesses/${businessId}/credentials/hometax/certificate`;
      },
    },
    cards(businessId: string) {
      return `/businesses/${businessId}/credentials/cards`;
    },
    crefia: {
      login(businessId: string) {
        return `/businesses/${businessId}/credentials/crefia/login`;
      },
      business(businessId: string) {
        return `/businesses/${businessId}/credentials/crefia/business`;
      },
    },
  },
  mobile: {
    account: {
      edit() {
        return `/m/account/edit`;
      },
      invite() {
        return `/m/account/invite`;
      },
      deactivate() {
        return `/m/account/deactivate`;
      },
    },
    intent: {
      kakao(params: Params) {
        return `/m/intent/kakao/invite?${stringify(params)}&from=/invite`;
      },
    },
    businesses: {
      index() {
        return '/m/businesses';
      },
      new(query?: Params) {
        return `/m/businesses/new${toString(query)}`;
      },
      newFranchise(branchId: string) {
        return `/m/businesses/new?branchId=${branchId}`;
      },
      edit(businessId: string) {
        return `/m/businesses/${businessId}/edit`;
      },
      delete(businessId: string) {
        return `/m/businesses/${businessId}/delete`;
      },
      members(businessId: string) {
        return `/m/businesses/${businessId}/members`;
      },
      taxAccountants(businessId: string) {
        return `/m/businesses/${businessId}/tax-accountants`;
      },
    },
    home(businessId: string) {
      return `/m/businesses/${businessId}/`;
    },
    settings(businessId: string) {
      return `/m/businesses/${businessId}/settings`;
    },
    settingsDetail(businessId: string) {
      return `/m/businesses/${businessId}/detailed-settings`;
    },
    unpaidFeature(businessId: string) {
      return `/m/businesses/${businessId}/unpaid-feature`;
    },
    managerFeature(businessId: string) {
      return `/m/businesses/${businessId}/add-manager`;
    },
    requireSubscription(businessId: string, featureName?: string) {
      const params = featureName ? `?featureName=${featureName}` : '';
      return `/m/businesses/${businessId}/require-subscription${params}`;
    },
    cardSales: {
      transactions(businessId: string, view?: string) {
        const params = view ? `?view=${view}` : '';
        return `/m/businesses/${businessId}/card-sales/transactions${params}`;
      },
      payments(businessId: string) {
        return `/m/businesses/${businessId}/card-sales/payments`;
      },
      crosschecks: {
        canceledPurchases(businessId: string) {
          return `/m/businesses/${businessId}/card-sales/crosschecks/canceled-purchases`;
        },
        purchases(businessId: string) {
          return `/m/businesses/${businessId}/card-sales/crosschecks/purchases`;
        },
        payments(businessId: string) {
          return `/m/businesses/${businessId}/card-sales/crosschecks/payments`;
        },
      },
    },
    integrations: {
      crefia: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/crefia`;
        },
        new: {
          business(businessId: string) {
            return `/m/businesses/${businessId}/integrations/crefia/business`;
          },
          login(businessId: string) {
            return `/m/businesses/${businessId}/integrations/crefia/login`;
          },
          realtime(businessId: string) {
            return `/m/businesses/${businessId}/integrations/crefia/realtime`;
          },
        },
        confirmation(businessId: string) {
          return `/m/businesses/${businessId}/integrations/crefia/confirmation`;
        },
      },
      hometax: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/hometax`;
        },
        new(businessId: string) {
          return `/m/businesses/${businessId}/integrations/hometax/new`;
        },
      },
      cards: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/cards`;
        },
        new: {
          index(businessId: string) {
            return `/m/businesses/${businessId}/integrations/cards/new`;
          },
          card(businessId: string, issuerName: string) {
            return `/m/businesses/${businessId}/integrations/cards/new/${issuerName}`;
          },
        },
      },
      bankAccounts: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/bank-accounts`;
        },
        new(businessId: string) {
          return `/m/businesses/${businessId}/integrations/bank-accounts/new`;
        },
      },
      deliveryApps: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/delivery-apps`;
        },
        baemin(businessId: string) {
          return `/m/businesses/${businessId}/integrations/delivery-apps/baemin`;
        },
        coupangEats(businessId: string) {
          return `/m/businesses/${businessId}/integrations/delivery-apps/coupangeats`;
        },
        yogiyo(businessId: string) {
          return `/m/businesses/${businessId}/integrations/delivery-apps/yogiyo`;
        },
      },
      places: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/places`;
        },
        naver(businessId: string) {
          return `/m/businesses/${businessId}/integrations/places/naver`;
        },
        daum(businessId: string) {
          return `/m/businesses/${businessId}/integrations/places/daum`;
        },
        google(businessId: string) {
          return `/m/businesses/${businessId}/integrations/places/google`;
        },
      },
      unifiedAuth: {
        success(businessId: string) {
          return `/m/businesses/${businessId}/integrations/unified-auth/success`;
        },
      },
      utilityBills: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/utility-bills`;
        },
        electricity(businessId: string) {
          return `/m/businesses/${businessId}/integrations/utility-bills/electricity`;
        },
        water(businessId: string) {
          return `/m/businesses/${businessId}/integrations/utility-bills/water`;
        },
        phone(businessId: string) {
          return `/m/businesses/${businessId}/integrations/utility-bills/phone`;
        },
      },
      pos: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/pos`;
        },
        intro(businessId: string) {
          return `/m/businesses/${businessId}/integrations/pos/intro`;
        },
        guide(businessId: string) {
          return `/m/businesses/${businessId}/integrations/pos/guide`;
        },
        connect: {
          index(businessId: string) {
            return `/m/businesses/${businessId}/integrations/pos/connect`;
          },
          checkIdentity(businessId: string) {
            return `/m/businesses/${businessId}/integrations/pos/connect/check-identity`;
          },
          checkRepresentative(businessId: string) {
            return `/m/businesses/${businessId}/integrations/pos/connect/check-representative`;
          },
          login(businessId: string) {
            return `/m/businesses/${businessId}/integrations/pos/connect/login`;
          },
        },
      },
      marketAnalysis: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/integrations/market-analysis`;
        },
        checkIdentity(businessId: string) {
          return `/m/businesses/${businessId}/integrations/market-analysis/check-identity`;
        },
        checkRepresentative(businessId: string) {
          return `/m/businesses/${businessId}/integrations/market-analysis/check-representative`;
        },
        subscribe(businessId: string) {
          return `/m/businesses/${businessId}/integrations/market-analysis/subscribe`;
        },
        completed(businessId: string) {
          return `/m/businesses/${businessId}/integrations/market-analysis/completed`;
        },
      },
    },
    merlin: {
      customPurchases(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/직접입력/비용${toString(query)}`;
      },
      createCustomPurchases(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/직접입력/비용/등록${toString(
          query
        )}`;
      },
      customSales(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/직접입력/매출${toString(query)}`;
      },
      createCustomSales(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/직접입력/매출/등록${toString(
          query
        )}`;
      },
      integrations: {
        realtimeCardSales: {
          start(businessId: string) {
            return `/m/businesses/${businessId}/연결/카드매출/실시간/시작`;
          },
        },
        cards: {
          personal(businessId: string) {
            return `/m/businesses/${businessId}/연결/카드/목록/개인카드`;
          },
        },
      },
    },
    notifications(businessId: string) {
      return `/m/businesses/${businessId}/notifications`;
    },
    notificationFeed(businessId: string, since?: string) {
      const params = since ? `?since=${since}` : '';
      return `/m/businesses/${businessId}/notification-feed${params}`;
    },
    payment: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/payment`;
      },
      plans: {
        index(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/payment/plans${toString(query)}`;
        },
        upgrade(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/payment/plans/upgrade${toString(
            query
          )}`;
        },
        downgradeSurvey(businessId: string) {
          return `/m/businesses/${businessId}/payment/plans/downgrade-survey`;
        },
        upgradeSuccess(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/payment/plans/upgrade/success${toString(
            query
          )}`;
        },
      },
      methods: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/payment/methods`;
        },
        add(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/payment/methods/add${toString(
            query
          )}`;
        },
        update(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/payment/methods/update${toString(
            query
          )}`;
        },
      },
      receipts(businessId: string, billingId: number) {
        return `/m/businesses/${businessId}/payment/receipts/${billingId}`;
      },
    },
    cardPurchases(businessId: string, query?: Params) {
      return `/m/businesses/${businessId}/card-purchases/payments${toString(
        query
      )}`;
    },
    cardPurchaseBills(businessId: string, query?: Params) {
      return `/m/businesses/${businessId}/card-purchases/bills${toString(
        query
      )}`;
    },
    cardPurchaseBillDetail(businessId: string, query: Params) {
      return `/m/businesses/${businessId}/card-purchases/bill-detail${toString(
        query
      )}`;
    },
    bankTransactions(businessId: string, query?: Params) {
      return `/m/businesses/${businessId}/bank-transactions${toString(query)}`;
    },
    invoices: {
      purchases(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/invoices/purchases${toString(
          query
        )}`;
      },
      sales(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/invoices/sales${toString(query)}`;
      },
    },
    cashReceipts: {
      purchases(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/cash-receipts/purchases${toString(
          query
        )}`;
      },
      sales(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/cash-receipts/sales${toString(
          query
        )}`;
      },
    },
    deliveryAppSales: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/delivery-app-sales`;
      },
      payments(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/delivery-app-sales/payments${toString(
          query
        )}`;
      },
      orders(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/delivery-app-sales/orders${toString(
          query
        )}`;
      },
    },
    utilityBills: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/utility-bills`;
      },
      water(businessId: string) {
        return `/m/businesses/${businessId}/utility-bills/water`;
      },
      electricity(businessId: string, credentialId?: string) {
        return `/m/businesses/${businessId}/utility-bills/electricity/${
          credentialId || ''
        }`;
      },
      phone(businessId: string) {
        return `/m/businesses/${businessId}/utility-bills/phone`;
      },
    },
    taxPayments: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/tax-payments`;
      },
      vat(businessId: string) {
        return `/m/businesses/${businessId}/tax-payments/vat`;
      },
      withholding(businessId: string) {
        return `/m/businesses/${businessId}/tax-payments/withholding`;
      },
    },
    placeReviews(businessId: string) {
      return `/m/businesses/${businessId}/place-reviews`;
    },
    crosschecks: {
      purchases(businessId: string) {
        return `/m/businesses/${businessId}/crosschecks/purchases`;
      },
      payments(businessId: string) {
        return `/m/businesses/${businessId}/crosschecks/payments`;
      },
    },
    cardMerchants(businessId: string) {
      return `/m/businesses/${businessId}/card-merchants`;
    },
    cardFeeRefunds(businessId: string) {
      return `/m/businesses/${businessId}/card-fee-refunds`;
    },
    taxReductions(businessId: string) {
      return `/m/businesses/${businessId}/tax-reductions`;
    },
    taxPricings(businessId: string) {
      return `/m/businesses/${businessId}/tax-pricings`;
    },
    invalidInvoices: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/invalid-invoices`;
      },
      purchases(businessId: string) {
        return `/m/businesses/${businessId}/invalid-invoices/purchases`;
      },
      sales(businessId: string) {
        return `/m/businesses/${businessId}/invalid-invoices/sales`;
      },
    },
    unpaidInvoices: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/unpaid-invoices`;
      },
      vendor(businessId: string) {
        return `/m/businesses/${businessId}/unpaid-invoices/vendor`;
      },
      customer(businessId: string) {
        return `/m/businesses/${businessId}/unpaid-invoices/customer`;
      },
    },
    unreceivedInvoices: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/unreceived-invoices`;
      },
      purchases(businessId: string) {
        return `/m/businesses/${businessId}/unreceived-invoices/purchases`;
      },
      sales(businessId: string) {
        return `/m/businesses/${businessId}/unreceived-invoices/sales`;
      },
      reports(businessId: string) {
        return `/m/businesses/${businessId}/unreceived-invoices-reports`;
      },
    },
    bookRenewalIntroPopup(businessId: string) {
      return `/m/businesses/${businessId}/장부개편소개`;
    },
    finance: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/finance`;
      },
      reports: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/finance/reports`;
        },
        cashFlow(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/finance/reports/cash-flow${toString(
            query
          )}`;
        },
        sales(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/finance/reports/sales${toString(
            query
          )}`;
        },
        expenses(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/finance/reports/expenses${toString(
            query
          )}`;
        },
      },
      history(businessId: string) {
        return `/m/businesses/${businessId}/finance/history`;
      },
      calendar: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/finance/calendar`;
        },
        cashFlow(businessId: string) {
          return `/m/businesses/${businessId}/finance/calendar/cash-flow`;
        },
        sales(businessId: string) {
          return `/m/businesses/${businessId}/finance/calendar/sales`;
        },
        expenses(businessId: string) {
          return `/m/businesses/${businessId}/finance/calendar/expenses`;
        },
      },
      traders: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/finance/traders`;
        },
      },
    },
    quarterlyReports(businessId: string) {
      return `/m/businesses/${businessId}/quarterly-reports`;
    },
    marketing: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/marketing`;
      },
      places(businessId: string) {
        return `/m/businesses/${businessId}/marketing/places`;
      },
      customerReports(businessId: string) {
        return `/m/businesses/${businessId}/marketing/customer-reports`;
      },
      marketReports(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/marketing/market-reports${toString(
          query
        )}`;
      },
    },
    insights: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/insights`;
      },
      sales(businessId: string) {
        return `/m/businesses/${businessId}/insights/sales`;
      },
      tax(businessId: string) {
        return `/m/businesses/${businessId}/insights/tax`;
      },
      loan(businessId: string) {
        return `/m/businesses/${businessId}/insights/loan`;
      },
      creditCard(businessId: string) {
        return `/m/businesses/${businessId}/insights/credit-card`;
      },
      traders(businessId: string) {
        return `/m/businesses/${businessId}/insights/traders`;
      },
    },
    traders(businessId: string) {
      return `/m/businesses/${businessId}/insights/traders`;
    },
    customerReports(businessId: string) {
      return `/m/businesses/${businessId}/customer-reports`;
    },
    marketReports(businessId: string, quarter?: string) {
      const params = quarter ? `?quarter=${quarter}` : '';
      return `/m/businesses/${businessId}/market-reports${params}`;
    },
    invalidInvoicesReports(businessId: string) {
      return `/m/businesses/${businessId}/invalid-invoices-reports`;
    },
    vendors(businessId: string, registrationNumber: string) {
      return `/m/businesses/${businessId}/vendors/${registrationNumber}`;
    },
    customers(businessId: string, registrationNumber: string) {
      return `/m/businesses/${businessId}/customers/${registrationNumber}`;
    },
    receivableTaxRefunds(businessId: string) {
      return `/m/businesses/${businessId}/receivable-tax-refunds`;
    },
    포스_대시보드: {
      index(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/포스-대시보드${toString(query)}`;
      },
    },
    사장님_비서: {
      index(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/사장님-비서${toString(query)}`;
      },
      시작: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/사장님-비서/시작`;
        },
        본인인증(businessId: string) {
          return `/m/businesses/${businessId}/사장님-비서/시작/본인인증`;
        },
        카드매출(businessId: string) {
          return `/m/businesses/${businessId}/사장님-비서/시작/카드매출`;
        },
        인증서(businessId: string) {
          return `/m/businesses/${businessId}/사장님-비서/시작/인증서`;
        },
      },
      지원정책: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/사장님-비서/지원정책`;
        },
        상세(businessId: string, aidId: string) {
          return `/m/businesses/${businessId}/사장님-비서/지원정책/${aidId}?scrollToId=aid-${aidId}`;
        },
      },
      금융상품: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/사장님-비서/금융상품`;
        },
        보험(businessId: string) {
          return `/m/businesses/${businessId}/사장님-비서/금융상품?scrollToId=보험상품`;
        },
      },
      주문_배달_통합관리: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리`;
        },
        주문하기: {
          index(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/주문하기`;
          },
          해지(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/주문하기/해지`;
          },
          사업자정보(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/주문하기/사업자정보`;
          },
          매장정보(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/주문하기/매장정보`;
          },
          운영정보(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/주문하기/운영정보`;
          },
        },
        메뉴관리: {
          index(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/메뉴관리`;
          },
          메뉴목록(businessId: string, orderType: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/메뉴관리/${orderType}`;
          },
          그룹관리(businessId: string, orderType: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/메뉴관리/${orderType}/그룹관리`;
          },
          등록(businessId: string, orderType: string, query?: Params) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/메뉴관리/${orderType}/등록${toString(
              query
            )}`;
          },
          수정(businessId: string, menuId: string, orderType: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/메뉴관리/${orderType}/수정/${menuId}`;
          },
          순서변경(businessId: string, orderType: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/메뉴관리/${orderType}/순서변경`;
          },
          품절관리(businessId: string, orderType: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/메뉴관리/${orderType}/품절관리`;
          },
        },
        신청: {
          완료(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/신청/완료`;
          },
        },
        포스: {
          intro(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/포스/intro`;
          },
          설치방법(businessId: string) {
            return `/m/businesses/${businessId}/사장님-비서/주문-배달-통합관리/포스/설치방법`;
          },
        },
      },
    },
    주변_상권_현황(businessId: string) {
      return `/m/businesses/${businessId}/주변-상권-현황`;
    },
    매출_자료: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/매출-자료`;
      },
      소개(businessId: string) {
        return `/m/businesses/${businessId}/매출-자료/소개`;
      },
      카드매출(businessId: string) {
        return `/m/businesses/${businessId}/매출-자료/카드매출`;
      },
    },
    사장님_뉴스: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/사장님-뉴스`;
      },
    },
    individualIncomeTax: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/individual-income-tax`;
      },
      sample(businessId: string) {
        return `/m/businesses/${businessId}/individual-income-tax/sample`;
      },
      checkIdentity(businessId: string) {
        return `/m/businesses/${businessId}/individual-income-tax/check-identity`;
      },
      checkHometax(businessId: string) {
        return `/m/businesses/${businessId}/individual-income-tax/check-hometax`;
      },
      report(businessId: string) {
        return `/m/businesses/${businessId}/individual-income-tax/report`;
      },
    },
    vat: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/vat`;
      },
      return: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/vat/return`;
        },
        checkHometax(businessId: string) {
          return `/m/businesses/${businessId}/vat/return/check-hometax`;
        },
        generateForm(
          businessId: string,
          options?: { subPaths?: string[]; editing?: boolean }
        ) {
          const { subPaths, ...rest } = options || {};

          const subPathStrings = subPaths ? `/${subPaths.join('/')}` : '';

          return `/m/businesses/${businessId}/vat/return/generate-form${subPathStrings}${toString(
            rest
          )}`;
        },
        submitForm(businessId: string, subPath?: string) {
          const subPathString = subPath ? `/${subPath}` : '';

          return `/m/businesses/${businessId}/vat/return/submit-form${subPathString}`;
        },
      },
    },
    vatReports(businessId: string) {
      return `/m/businesses/${businessId}/vat-reports`;
    },
    upcomingBills(businessId: string) {
      return `/m/businesses/${businessId}/upcoming-bills`;
    },
    노란우산공제: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/노란우산공제`;
      },
      법인제한(businessId: string) {
        return `/m/businesses/${businessId}/노란우산공제/법인제한`;
      },
      외국인제한(businessId: string) {
        return `/m/businesses/${businessId}/노란우산공제/외국인제한`;
      },
      신청불가(businessId: string) {
        return `/m/businesses/${businessId}/노란우산공제/신청불가`;
      },
      hometax: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/노란우산공제/hometax`;
        },
        validating(businessId: string) {
          return `/m/businesses/${businessId}/노란우산공제/hometax/validating`;
        },
        invalid(businessId: string) {
          return `/m/businesses/${businessId}/노란우산공제/hometax/invalid`;
        },
        certificate(businessId: string) {
          return `/m/businesses/${businessId}/노란우산공제/hometax/certificate`;
        },
      },
      청약서작성: {
        index(businessId: string) {
          return `/m/businesses/${businessId}/노란우산공제/청약서작성?refreshIdentity=true`;
        },
        신청서정보확인(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/노란우산공제/청약서작성/신청서정보확인${toString(
            query
          )}`;
        },
        신분증인증(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/노란우산공제/청약서작성/신분증인증${toString(
            query
          )}`;
        },
        신청서입력(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/노란우산공제/청약서작성/신청서입력${toString(
            query
          )}`;
        },
        청약상세내용입력(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/노란우산공제/청약서작성/청약상세내용입력${toString(
            query
          )}`;
        },
        예상매출액작성(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/노란우산공제/청약서작성/예상매출액작성${toString(
            query
          )}`;
        },
        청약서작성내용확인(businessId: string, query?: Params) {
          return `/m/businesses/${businessId}/노란우산공제/청약서작성/청약서작성내용확인${toString(
            query
          )}`;
        },
      },
      신청완료(businessId: string) {
        return `/m/businesses/${businessId}/노란우산공제/신청완료`;
      },
    },
    페이노트_고객관리: {
      index(businessId: string) {
        return `/m/businesses/${businessId}/페이노트-고객관리`;
      },
    },
    신용점수조회: {
      index(businessId: string, query?: Params) {
        return `/m/businesses/${businessId}/신용점수조회${toString(query)}`;
      },
      신용_현황(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/신용-현황`;
      },
      신용_현황_카드(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/신용-현황/카드`;
      },
      신용_현황_대출(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/신용-현황/대출`;
      },
      신용_현황_연체(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/신용-현황/연체`;
      },
      변동이력(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/변동이력`;
      },
      조회불가(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/조회불가`;
      },
      본인인증(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/본인인증`;
      },
      시작(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/시작`;
      },
      수집중(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/수집중`;
      },
      인증에러(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/인증에러`;
      },
      수집에러(businessId: string) {
        return `/m/businesses/${businessId}/신용점수조회/수집에러`;
      },
    },
  },
};
