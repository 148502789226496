import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

const LastLocationContext = React.createContext({
  lastLocation: null,
});
const { Provider, Consumer } = LastLocationContext;

class LastLocationProvider extends Component {
  state = {
    lastLocation: null,
  };

  componentDidUpdate(prevProps) {
    const current = this.props.location;
    const last = prevProps.location;
    if (current.pathname === last.pathname && current.search === last.search) {
      return;
    }

    if (
      !current.search.includes('scroll=') &&
      !window.history.state?.state?.preserveScroll
    ) {
      window.scrollTo(0, 0);
    }

    this.setState({
      lastLocation: { ...last },
    });
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const withLastLocation = (Component) => (props) => (
  <Consumer>
    {({ lastLocation }) => <Component {...props} lastLocation={lastLocation} />}
  </Consumer>
);

const connectedProvider = withRouter(LastLocationProvider);

export {
  LastLocationContext,
  connectedProvider as LastLocationProvider,
  Consumer as LastLocationConsumer,
  withLastLocation,
};
