import storage from 'local-storage-fallback';

import type { AppFeatureKey } from './constants';

import {
  isIos,
  isAndroid,
  isAndroidAppWebView,
  isAppWebView,
  platform,
  isEnoughForTargetAppVersion,
  isAppV2,
} from 'utils/userAgent';
import { APP_FEATURE_MIN_SUPPORT_VERSION } from './constants';
import apolloClient from 'graphql/apolloClient';

window.ApolloClient = {
  resetStore: async () => {
    apolloClient.cache.reset();
    await Object.values(
      window.ApolloClient.observableQueries
    ).forEach((callback: any) => callback());
    await apolloClient.reFetchObservableQueries();
  },
  observableQueries: {},
  subscribeToResetStore: (key: string, callback: Function) => {
    window.ApolloClient.observableQueries[key] = callback;
  },
  unsubscribeFromResetStore: (key: string) => {
    delete window.ApolloClient.observableQueries[key];
  },
};

window.CashnoteAlert = ({
  title,
  description,
  confirmText = '확인',
  fullMessage,
}) => {
  const fallback = fullMessage || description;
  const message = hasAppFeature('richDialog')
    ? JSON.stringify({
        title,
        description,
        confirmText,
      })
    : fallback;

  window.alert(message);
};

window.CashnoteConfirm = ({
  title,
  description,
  cancelText = '취소',
  confirmText = '확인',
  fullMessage,
}) => {
  const fallback = fullMessage || description;
  const message = hasAppFeature('richDialog')
    ? JSON.stringify({
        title,
        description,
        cancelText,
        confirmText,
      })
    : fallback;

  return window.confirm(message);
};

window.CashnoteSetFeatures = (features) => {
  storage.setItem('appFeatures', features);
  return true;
};

export function hasAppFeature(featureKey: AppFeatureKey) {
  if (!isAppWebView()) {
    return false;
  }

  // @ts-ignore
  const features = APP_FEATURE_MIN_SUPPORT_VERSION[platform()];

  // @ts-ignore
  const targetVersion = features[featureKey] as string | undefined;

  if (!targetVersion) {
    return false;
  }

  return isEnoughForTargetAppVersion(targetVersion);
}

export function linkToApp() {
  if (isIos()) {
    return 'https://itunes.apple.com/us/app/id1459090715?l=ko';
  }

  if (!isAppV2()) {
    return isAndroidAppWebView() && hasAppFeature('urlScheme/market')
      ? 'market://details?id=cashnote.com.albatross'
      : 'https://play.google.com/store/apps/details?id=cashnote.com.albatross';
  }

  // android에서 market scheme에 대한 대응이 안 되어있음
  return 'https://play.google.com/store/apps/details?id=cashnote.com.albatross';
}

export function marketLink() {
  if (isIos()) {
    return 'itms-apps://itunes.apple.com/us/app/id1459090715?l=ko';
  } else if (isAndroid()) {
    return 'market://details?id=cashnote.com.albatross';
  }

  return null;
}

export * from './messageInterface';
