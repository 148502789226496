import { useQuery, useMutation } from '@apollo/react-hooks';

import type { OperationVariables, QueryResult } from '@apollo/react-common';
import type { DocumentNode } from 'graphql';
import type {
  QueryHookOptions,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/react-hooks';

import { useCurrentBusinessId } from 'hooks/useCurrentBusiness';

export const PHOENIX_TARGET = 'PHOENIX';

export function usePhoenixQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const businessId = useCurrentBusinessId();

  return useQuery(query, {
    context: {
      target: PHOENIX_TARGET,
      headers: {
        'x-api-key': process.env.REACT_APP_PHOENIX_API_KEY,
        BusinessId: businessId,
      },
    },
    fetchPolicy: 'network-only',
    ...(options || {}),
  });
}

export function usePhoenixMutation<
  TData = any,
  TVariables = OperationVariables
>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const businessId = useCurrentBusinessId();

  return useMutation(mutation, {
    context: {
      target: PHOENIX_TARGET,
      headers: {
        'x-api-key': process.env.REACT_APP_PHOENIX_API_KEY,
        BusinessId: businessId,
      },
    },
    ...(options || {}),
  });
}
