import styled from 'styled-components/macro';

const Error = styled.main`
  max-width: 768px;
  margin: auto;
  padding: 20px;
  text-align: center;
`;

export default Error;
