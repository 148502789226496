import { systemFontScale } from '@kcd/app-interface';

import insertStyleRule from '../insertStyleRule';
import { CSSProperties } from 'react';

const LOCAL_FONT_SCALE = 1.0; // 로컬에서 개발할 땐 이 값을 변경합니다. 모바일에서만 적용됩니다.

interface StylesParams {
  on1_0x?: CSSProperties; // 1.0이상 ~ 1.3미만
  on1_3x?: CSSProperties; // 1.3이상 ~ 1.5미만
  on1_5x?: CSSProperties; // 1.5이상 ~ 1.7미만
  on1_7x?: CSSProperties; // 1.7이상
}

// https://koreacreditdata.atlassian.net/wiki/spaces/ENG/pages/3578462416#to-FE
class FontScale {
  private value = 1;

  set(value: number) {
    this.value = value;
  }

  private get = () => this.value;
  private is1_3x = () => this.get() >= 1.3;
  private is1_5x = () => this.get() >= 1.5;
  private is1_7x = () => this.get() >= 1.7;

  private filterStyle(params: StylesParams): CSSProperties[] {
    const { on1_0x = {}, on1_3x = {}, on1_5x = {}, on1_7x = {} } = params;

    const filteredStyles = [
      [on1_0x, true] as const,
      [on1_3x, this.is1_3x()] as const,
      [on1_5x, this.is1_5x()] as const,
      [on1_7x, this.is1_7x()] as const,
    ]
      .filter((item) => item[1])
      .flatMap((item) => item[0]);

    return filteredStyles;
  }

  private dedupeStyle = (styles: CSSProperties[]): CSSProperties => {
    const dedupedStyle = styles.reduce((acc, cur) => ({ ...acc, ...cur }));
    return dedupedStyle;
  };

  style(params: StylesParams) {
    const styles = this.filterStyle(params);
    return this.dedupeStyle(styles);
  }
}

export const fontScale = new FontScale();

export function initializeFontScale() {
  const value =
    process.env.NODE_ENV === 'development'
      ? LOCAL_FONT_SCALE
      : systemFontScale();
  fontScale.set(value);

  insertStyleRule(
    () => `
  body {
    text-size-adjust: ${value * 100}%;
    -webkit-text-size-adjust: ${value * 100}%;
  }`
  );
}
