import { STORAGE_ITEMS } from 'hooks/useStorage';
import cookie from './cookie';

export type ConnectPlatformType = 'pos' | 'android-terminal';

function getValue(type: 'vendor' | 'platform' | 'businessId') {
  const value = cookie.get(type);
  return value === 'unknown' ? null : value;
}

function getPlatform() {
  return getValue(STORAGE_ITEMS.PLATFORM) as ConnectPlatformType | null;
}

function isPosPlatform() {
  return getPlatform() === 'pos';
}

function isAndroidTerminalPlatform() {
  return getPlatform() === 'android-terminal';
}

function isFromConnectService() {
  return isPosPlatform() || isAndroidTerminalPlatform();
}

const connectCookieService = {
  getPlatform,
  isPosPlatform,
  isAndroidTerminalPlatform,
  isFromConnectService,
};

export default connectCookieService;
