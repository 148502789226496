import { useDispatch } from 'react-redux';
import gql from 'graphql-tag.macro';
import { useMutation } from '@apollo/react-hooks';

import ACCOUNT_INFO from 'graphql/fragments/account.fragment';

import { refresh } from 'ducks/auth';
import { logError } from 'utils/errorLogger';

const REFRESH_JWT = gql`
  mutation refreshJwt {
    refreshJwt {
      result {
        __typename

        ... on RefreshJwtSuccess {
          jwt
          user {
            id
            ...AccountInfo
          }
        }

        ... on UserNotFoundError {
          message
        }
      }
    }
  }
  ${ACCOUNT_INFO}
`;

export default function useAuthRefresh() {
  const dispatch = useDispatch();
  const [startRefresh] = useMutation(REFRESH_JWT, {
    onCompleted: (data) => dispatch(refresh(data)),
    onError: (error) => logError(error),
    fetchPolicy: 'no-cache',
  });

  return startRefresh;
}
