import moment from 'moment';
import { isAppWebView } from 'utils/userAgent';

export const KESTREL_HOST = {
  production: 'https://app.cashnote.kr',
  staging: 'https://app-staging.cashnote.kr',
  development: 'https://dev-app.cashnote.kr',
  local: 'http://localhost:3001',
}[
  process.env.NODE_ENV === 'development'
    ? 'local'
    : process?.env?.REACT_APP_DEPLOY_ENV ?? 'production'
]!;

export const V2_HOST = {
  production: 'https://app2.cashnote.kr',
  staging: 'https://app2-staging.cashnote.kr',
  development: 'https://dev-app2.cashnote.kr',
}[process?.env?.REACT_APP_DEPLOY_ENV ?? 'production']!;

export const APPS_HOST = {
  production: 'https://apps.cashnote.kr',
  staging: 'https://app2-staging.cashnote.kr',
  development: 'https://dev-app2.cashnote.kr',
}[process?.env?.REACT_APP_DEPLOY_ENV ?? 'production']!;

export const URL_MAP = {
  settings: '설정',
  'detailed-settings': '설정',
  'tax-accountants': '설정/세무대리인',
  notifications: '설정/알림',
  'subscription/start': '결제/이용권',
  payment: '결제',
  'marketing/places': '고객-리뷰-관리/통계',
  'place-reviews': '고객-리뷰-관리/리뷰-목록',
  'payment/plans': '결제/이용권',
  'payment/plans/upgrade': '결제/이용권/구독',
  'payment/methods': '결제/결제수단',
  'payment/methods/update': '결제/결제수단/변경',
  'payment/methods/add': '결제/결제수단/추가',
  'integrations/utility-bills': '연결/공과금',
  'integrations/utility-bills/electricity': '연결/공과금/전기요금',
  'integrations/delivery-apps': '연결/배달앱',
  'integrations/delivery-apps/baemin': '연결/배달앱/배달의민족',
  'integrations/delivery-apps/yogiyo': '연결/배달앱/요기요',
  'integrations/delivery-apps/coupangeats': '연결/배달앱/쿠팡이츠',
  'integrations/bank-accounts': '연결/은행계좌',
  'integrations/bank-accounts/new': '연결/은행계좌/추가',
  'integrations/places': '연결/지도',
  'integrations/places/naver': '연결/지도/네이버지도',
  'integrations/places/daum': '연결/지도/카카오맵',
  'integrations/places/google': '연결/지도/구글지도',
  'integrations/cards': '연결/카드',
  'integrations/cards/new': '연결/카드/추가',
  'integrations/crefia': '연결/카드매출',
  'integrations/crefia/business': '연결/카드매출/가맹점인증',
  'integrations/crefia/realtime': '연결/카드매출/실시간',
  'integrations/crefia/login': '연결/카드매출/여신금융협회',
  'integrations/pos': '연결/포스',
  'integrations/pos/guide': '포스',
  'integrations/pos/intro': '연결/포스/소개',
  'integrations/hometax': '연결/홈택스',
  'integrations/hometax/new': '연결/홈택스/아이디',
  'insights/loan': '추천/대출',
  'insights/credit-card': '추천/카드',
  // 2.0에만 존재하는 페이지라 kestrel에 별도의 route 생성하지 않음
  '연결/카드매출/실시간/시작': '연결/카드매출/실시간/시작',
  '연결/카드/목록/개인카드': '연결/카드/목록/개인카드',
  '직접입력/매출': '직접입력/매출',
  '직접입력/비용': '직접입력/비용',
  '직접입력/매출/등록': '직접입력/매출/등록',
  '직접입력/비용/등록': '직접입력/비용/등록',
  'book/renewal/intro': 'book/renewal/intro',
};

// kestrel to merlin core (api book versions 데이터를 기반으로 페이지를 변경)
const BOOK_URL_MAP: Record<string, { url: string; queryString?: string }> = {
  'finance/calendar': { url: '장부/실적/월별실적/영업실적' },
  'finance/calendar/cash-flow': { url: '장부/매출/매출입금/관리' },
  'finance/calendar/cash-flow/date': { url: '장부/매출/매출입금/목록/일별' },
  'finance/calendar/sales': { url: '장부/실적/월별실적/영업실적' },
  'finance/calendar/sales/date': { url: '장부/실적/일별실적' },
  'finance/calendar/expenses': { url: '장부/실적/월별실적/영업실적' },
  'finance/calendar/expenses/date': { url: '장부/실적/일별실적' },
  'finance/reports': { url: '장부/매출/매출입금/관리' },
  'finance/reports/cash-flow': { url: '장부/매출/매출입금/관리' },
  'finance/reports/cash-flow/date': { url: '장부/매출/매출입금/목록/일별' },
  'finance/reports/sales': { url: '장부/실적/월별실적/비교및분석' },
  'finance/reports/sales/date': { url: '장부/실적/일별실적' },
  'finance/reports/expenses': { url: '장부/실적/월별실적/비교및분석' },
  'finance/reports/expenses/date': { url: '장부/실적/일별실적' },
  'card-sales/payments': {
    url: '장부/매출/매출입금/목록/월별',
    queryString: 'source_type=CARD',
  },
  'card-sales/payments/date': {
    url: '장부/매출/매출입금/목록/일별',
    queryString: 'source_type=CARD',
  },
  'card-sales/transactions': { url: '장부/매출/카드매출/월별' },
  'card-sales/transactions/date': { url: '장부/매출/카드매출/일별' },
  'cash-receipts/purchases': {
    url: '장부/비용/통합비용목록/월별',
    queryString: 'source_type=CASH_RECEIPT',
  },
  'cash-receipts/purchases/date': {
    url: '장부/비용/통합비용목록/일별',
    queryString: 'source_type=CASH_RECEIPT',
  },
  'cash-receipts/sales': {
    url: '장부/매출/현금매출/월별',
    queryString: 'source_type=CASH_RECEIPT',
  },
  'cash-receipts/sales/date': {
    url: '장부/매출/현금매출/일별',
    queryString: 'source_type=CASH_RECEIPT',
  },
  'invoices/purchases': {
    url: '장부/비용/통합비용목록/월별',
    queryString: 'source_type=PURCHASE_INVOICE_ISSUED',
  },
  'invoices/purchases/date': {
    url: '장부/비용/통합비용목록/일별',
    queryString: 'source_type=PURCHASE_INVOICE_ISSUED',
  },
  'invoices/sales': { url: '장부/매출/세금계산서/월별' },
  'invoices/sales/date': { url: '장부/매출/세금계산서/일별' },
  'bank-transactions': { url: '장부/매출/계좌입출금/목록' },
  'bank-transactions/date': { url: '장부/매출/계좌입출금/목록' },
  'card-purchases/payments': {
    url: '장부/비용/통합비용목록/월별',
    queryString: 'source_type=CARD',
  },
  'card-purchases/payments/date': {
    url: '장부/비용/통합비용목록/일별',
    queryString: 'source_type=CARD',
  },
  'delivery-app-sales/payments': {
    url: '장부/매출/매출입금/목록/월별',
    queryString: 'source_type=DELIVERY_APP',
  },
  'delivery-app-sales/payments/date': {
    url: '장부/매출/매출입금/목록/일별',
    queryString: 'source_type=DELIVERY_APP',
  },
  'delivery-app-sales/orders': { url: '장부/매출/배달앱매출/월별' },
  'delivery-app-sales/orders/date': { url: '장부/매출/배달앱매출/일별' },
  'vat-reports': { url: '장부/세금/부가세' },
  'utility-bills/electricity': {
    url: '장부/공과금/전기요금',
  },
  'card-merchants': {
    url: '장부/매출/카드매출/정산정보',
  },
};

export function extract(path: string) {
  const matched = path.match(/businesses\/(\d+|first)\/(.+)/);

  if (!matched) {
    return null;
  }

  return [matched[1], matched[2]];
}

export function convertToV2Url(path: string, search?: string) {
  const extracted = extract(path);

  if (!extracted) {
    return null;
  }

  const [businessId, rest] = extracted;

  // @ts-ignore
  const v2Url = URL_MAP[rest.split('?')[0]];
  if (!v2Url) {
    return null;
  }

  const queryParmas = search ? `&${search.replace(/\?/, '')}` : ''; // 직접입력 작업에는 queryString이 함께 전달되어야 하므로 해당 코드 추가.

  return `${V2_HOST}/${v2Url}?businessId=${businessId}${queryParmas}`;
}

export function withHostnameUrl(pathname: string) {
  if (pathname.includes('://')) {
    return pathname;
  }

  const { protocol, hostname, port } = window.location;

  return protocol + '//' + hostname + (port ? ':' + port : '') + pathname;
}

export function convertToBookUrl() {
  const extracted = extract(window.location.href);
  const searchQueryParams = new URLSearchParams(window.location.search);

  if (!extracted) {
    return null;
  }
  const [businessId, rest] = extracted;
  const isQueryString = Boolean(searchQueryParams.toString());
  const isQueryKeyDay = searchQueryParams.has('day');
  const isQueryKeySelectedDate = searchQueryParams.has('selectedDate');
  const isQueryKeyOrder = searchQueryParams.has('order');
  const isQueryKeyByPass = searchQueryParams.has('by_pass');

  const urlMappingKey = `${rest.split('?')[0]}${
    isQueryKeyDay || isQueryKeySelectedDate ? '/date' : ''
  }`;
  const mappingBookData = BOOK_URL_MAP[urlMappingKey];
  let queryParmas = '';

  // check url and businessId
  if (!mappingBookData || !businessId) {
    return null;
  }

  if (!isAppWebView()) {
    // @NOTE 장부 제거 전까지 내부 확인을 위하여
    if (isQueryKeyByPass) {
      return null;
    }
    return `${V2_HOST}/book/entry-guide`;
  }

  // check query string
  if (isQueryString) {
    if (mappingBookData?.queryString) {
      queryParmas += `&${mappingBookData.queryString}`;
    }

    if (isQueryKeyDay) {
      queryParmas += `&date=${searchQueryParams.get('day')}`;
    }

    if (isQueryKeySelectedDate) {
      const convertDate = moment(searchQueryParams.get('selectedDate')).format(
        'D'
      );
      queryParmas += `&date=${convertDate}`;
    }

    if (isQueryKeyOrder) {
      queryParmas += `&source_type=${searchQueryParams.get('order')}`;
    }

    queryParmas += `&${searchQueryParams.toString()}`;
  }

  return `${V2_HOST}/${mappingBookData.url}?businessId=${businessId}${queryParmas}`;
}

const APP_ONLY_URL_MAP: Record<string, string> = {
  '포스-대시보드': '판매-관리',
  '포스-대시보드/체험용': '연결/포스/소개',
  '포스-대시보드/통계-미지원': '지원-불가-안내',
  '포스-대시보드/업데이트중': '확인-중-안내',
  'marketing/places': '고객-리뷰-관리/통계',
  'place-reviews': '고객-리뷰-관리/리뷰-목록',
  'marketing/customer-reports': 'marketing/customer-reports', // 방문 분석
  'marketing/market-reports': 'marketing/market-reports', // 상권 분석
};

function createAppInstallGuidePageUrl(businessId: string, url: string) {
  return `${V2_HOST}/안내/앱-설치?businessId=${businessId}&url=${url}`;
}

function createKestrelOnlyPageUrl(businessId: string, url: string) {
  if (isAppWebView()) {
    return null;
  }
  return createAppInstallGuidePageUrl(businessId, url);
}

function createMerlinPageUrl(
  businessId: string,
  newPathname: string,
  queryParams?: string,
  byPass?: boolean
) {
  if (isAppWebView()) {
    return `${V2_HOST}/${newPathname}?businessId=${businessId}&${
      queryParams || ''
    }`;
  }
  // @NOTE 내부 확인을 위하여 리디렉션 by pass
  if (byPass) {
    return null;
  }
  return createAppInstallGuidePageUrl(businessId, newPathname);
}

/**
 * 특정 url을 앱에서만 볼 수 있도록 제한하기 위한 맵핑 함수
 * 웹에서 특정 url에 접근할 경우 안내/앱-설치 페이지로 보내 앱 스킴을 호출합니다.
 * 앱에서 여는 경우, 케스트렐에만 있는 페이지는 아무 동작하지 않고,
 * 멀린으로 보내야 하는 페이지는 멀린으로 리디렉션합니다.
 */
export function convertToAppOnlyUrl() {
  const decodedKestrelUrl = decodeURI(window.location.href);
  const extracted = extract(decodedKestrelUrl);
  const searchParams = new URLSearchParams(window.location.search);

  if (!extracted) {
    return null;
  }
  const [businessId, rest] = extracted;
  const [pathname, queryParams] = rest.split('?');
  const newPathname = APP_ONLY_URL_MAP[pathname];
  const isKestrelOnlyPage = newPathname === pathname;

  if (!newPathname || !businessId) {
    return null;
  }

  if (isKestrelOnlyPage) {
    return createKestrelOnlyPageUrl(businessId, decodedKestrelUrl);
  }

  return createMerlinPageUrl(
    businessId,
    newPathname,
    queryParams,
    searchParams.has('by_pass') || searchParams.has('bypass')
  );
}

const V2_ONLY_URLS: Record<string, string> = {
  'integrations/cards': '연결/카드',
  'integrations/delivery-apps': '연결/배달앱',
  'integrations/bank-accounts': '연결/은행계좌',
};

export function convertToV2OnlyUrl() {
  const decodedKestrelUrl = decodeURI(window.location.href);
  const extracted = extract(decodedKestrelUrl);
  const searchParams = new URLSearchParams(window.location.search);

  if (!extracted) {
    return null;
  }
  const [businessId, rest] = extracted;
  const [pathname, queryParams] = rest.split('?');
  const newPathname = V2_ONLY_URLS[pathname];

  if (!newPathname || !businessId) {
    return null;
  }

  if (searchParams.has('by_pass') || searchParams.has('bypass')) {
    return null;
  }

  return `${V2_HOST}/${newPathname}?businessId=${businessId}&${
    queryParams || ''
  }`;
}
