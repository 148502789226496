import gql from 'graphql-tag.macro';

export default gql`
  query getAccount {
    account {
      id
      trackingId
      login
      name
      realName
      email
      phoneNumber
      hasPassword
      hasCI
      identityVerified
      identityVerifiedAt
      nationality
      inviteCode
      hasKakaoProfile
      trialStartedAt
      appLoggedIn
      featureFlags
      referralCode
      birthday
    }
  }
`;
