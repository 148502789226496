import { matchPath } from 'react-router';
import { parse as parseQueryString, stringify } from 'query-string';

import { isAppSupportedOS, isKakaoBrowser } from 'utils/userAgent';
import { activeMenuNameByPath } from 'utils/activeMenu';
import { marketLink } from 'utils/appWebView';
import { convertToV2Url } from 'merlin/utils/appWebView';

const protocol = 'cashnoteapp://';
const protocolV2 = 'cashnoteapp2://';

// 1.0 앱에서 연결하기 어려운 URL들(사업장 목록, 포스 연동, 상권노트)은 앱으로 열지 않고 카톡 웹뷰에서 연다
// 2.0 앱으로 전달할 때도, 사업장 목록은 표시할 수단이 없고, 나머지 페이지도 2.0 앱으로 굳이 연결할 필요가 없어서 유지
const FORCE_NOT_OPEN_APP_PATHS = {
  exact: ['/m/businesses'],
  relative: ['/m/businesses/:id/integrations/pos/connect', '/connect'],
};

const isAppSchemeAvailable = (location) => {
  if (
    matchPath(location.pathname, {
      exact: true,
      path: FORCE_NOT_OPEN_APP_PATHS.exact,
    }) ||
    matchPath(location.pathname, {
      path: FORCE_NOT_OPEN_APP_PATHS.relative,
    })
  ) {
    return false;
  }

  return isAppSupportedOS() && isKakaoBrowser();
};

const isLoginPath = (pathname) => pathname === '/' || pathname === '/login';

const appOpenUrl = (location) => {
  const { pathname, search, href } = location;

  if (isLoginPath(pathname)) {
    return `${protocol}tab/home/business/first`;
  }

  const menuName = activeMenuNameByPath(pathname);
  if (!menuName) {
    return `${protocol}tab/home/business/first/url/${href}`;
  }

  const matched = matchPath(pathname, {
    path: '/m/businesses/:businessId/:subPaths*',
  })?.params;

  if (matched) {
    const { businessId, subPaths } = matched;

    let url = `${protocol}tab/${menuName}/business/${businessId}/load/`;
    url += subPaths || '';

    if (search !== '') {
      const sortedSearch = stringify(parseQueryString(search));
      url += `?${sortedSearch}`;
    }

    return url;
  }

  return null;
};

const appV2OpenUrl = (location) => {
  const { pathname, search, href } = location;

  const decodedPath = decodeURI(pathname);
  const decodedHref = decodeURI(href);

  if (decodedPath.match(/^\/(login|signup|시작)/)) {
    return `${protocolV2}open`;
  }

  if (decodedPath.match('businesses/new')) {
    return `${protocolV2}open?page=addBusiness`;
  }

  if (decodedPath.match('account/invite')) {
    return `${protocolV2}open?tab=home&url=${base64EncodeUnicode(
      `친구-추천${search}`
    )}`;
  }

  const extracted = matchPath(decodedPath, {
    path: '/m/businesses/:businessId/:subPaths*',
  })?.params;

  if (!extracted) {
    return `${protocolV2}open`;
  }

  const { businessId, subPaths } = extracted;
  const converted = convertToV2Url(decodedPath);

  if (converted) {
    return `${protocolV2}open?businessId=${businessId}&url=${base64EncodeUnicode(
      converted + search.replace('?', '&')
    )}`;
  } else if (!subPaths) {
    return `${protocolV2}open?tab=home&businessId=${businessId}`;
  } else {
    return `${protocolV2}open?businessId=${businessId}&url=${base64EncodeUnicode(
      decodedHref
    )}`;
  }
};

const openApp = ({ fallbackToMarket = false } = {}) => {
  const v1Url = appOpenUrl(window.location);

  if (v1Url) {
    window.location.href = v1Url;
  }

  const iframe = document.createElement('iframe');
  iframe.style = 'display: none;';
  iframe.src = appV2OpenUrl(window.location);
  document.body.appendChild(iframe);

  if (fallbackToMarket) {
    window.setTimeout(() => window.location.replace(marketLink()), 300);
  }
};

const ENC = {
  '+': '-',
  '/': '_',
};

function base64EncodeUnicode(str) {
  return btoa(unescape(encodeURIComponent(str))).replace(
    /[+/]/g,
    (m) => ENC[m]
  );
}

export { isAppSchemeAvailable, openApp, appOpenUrl };
