import * as yuhina from '@kcd/event-tracker';

import { isMobile } from 'utils/userAgent';

const CREDENTIAL_EVENT_MAPS = {
  카드매출: '카드매출 연결',
  카드사: '실시간 카드매출 연결',
  홈택스: '홈택스 연결',
  은행계좌: '은행계좌 연결',
  카드: '카드 연결',
  배달앱: '배달앱 연결',
  배달의민족: '배달앱 연결',
  쿠팡이츠: '배달앱 연결',
  요기요: '배달앱 연결',
  공과금: '공과금 연결',
  전기: '공과금 연결',
  수도: '공과금 연결',
  통신: '공과금 연결',
  포스: '포스 연결',
};

type EventTracker = (
  name: string,
  properties?: any,
  options?: any,
  callback?: () => void
) => void;

export function identifyUser(userId: string, properties: Record<string, any>) {
  yuhina.identifyUser(userId, properties);
}

export function clearUser() {
  yuhina.clearUser();
}

export const track: EventTracker = (
  eventName,
  properties,
  options,
  callback
) => {
  if (!properties) {
    yuhina.trackEvent(eventName);
    return;
  }

  callback
    ? yuhina.trackEvent(eventName, properties, options, callback)
    : yuhina.trackEvent(eventName, properties);
};

export const trackClick = (
  type: string,
  name: string,
  { from, ...rest }: any
) =>
  yuhina.trackClick({
    type,
    name,
    pageName: from,
    ...rest,
  });

export const trackButtonClick: EventTracker = (
  buttonName,
  { from, ...rest } = {}
) =>
  trackClick('button', buttonName, {
    from,
    ...rest,
  });

export const trackAddCredentialButtonClick = (
  kind: keyof typeof CREDENTIAL_EVENT_MAPS,
  properties: any
): void => trackButtonClick(CREDENTIAL_EVENT_MAPS[kind], properties);

export const trackPageView: EventTracker = (pageName, properties) => {
  if (pageName && isMobile()) {
    yuhina.trackPageView({ pageName, properties });
  }
};

export const trackContentsView = (
  type: string,
  name: string,
  { from, ...rest }: any
) => yuhina.trackContentsView({ type, name, pageName: from, ...rest });

export const subscriptionPaymentProperties = (subscriptionPaymentInfo: any) => {
  const { trialStartable, card, subscription } = subscriptionPaymentInfo;

  return {
    isTrialStartable: trialStartable,
    hasPaymentMethod: !!card,
    hasSubscription: !!subscription,
    isSubscriptionPaid: subscription?.paid || false,
    willSubscriptionCancel: subscription?.pendingCancellation || false,
  };
};

type PredefinedEvent = {
  type:
    | 'viewed creative'
    | 'viewed content'
    | 'clicked creative'
    | 'clicked button'
    | 'clicked banner';
  name?: string;
  from?: string;
  startedAt: string;
};

type EventKind =
  | '부가세-계산기-CTA클릭'
  | '노우공-가입하기-버튼클릭'
  | 'MMP-소재-노출'
  | 'MMP-소재-클릭'
  | '홈-신용점수조회-클릭'
  | '추천탭-신용점수조회-클릭'
  | '홈-신용점수조회-카드클릭'
  | '페이지-CONTENT-노출';

const EVENT_LIST: { [key in EventKind]: PredefinedEvent } = {
  '부가세-계산기-CTA클릭': {
    type: 'clicked button',
    name: '홈택스 신고 방법 보러가기',
    from: '부가세 계산기',
    startedAt: '2021-02-01',
  },
  '노우공-가입하기-버튼클릭': {
    type: 'clicked button',
    name: '노란우산공제 가입하기',
    from: '노란우산공제',
    startedAt: '2021-02-24',
  },
  'MMP-소재-노출': {
    type: 'viewed creative',
    startedAt: '2021-03-08',
  },
  'MMP-소재-클릭': {
    type: 'clicked creative',
    startedAt: '2021-03-08',
  },
  '홈-신용점수조회-클릭': {
    type: 'clicked button',
    name: '신용점수조회',
    from: '홈',
    startedAt: '2021-05-20',
  },
  '추천탭-신용점수조회-클릭': {
    type: 'clicked banner',
    name: '신용점수조회',
    from: '추천',
    startedAt: '2021-05-20',
  },
  '홈-신용점수조회-카드클릭': {
    type: 'clicked banner',
    name: '신용점수조회',
    from: '홈',
    startedAt: '2021-06-04',
  },
  '페이지-CONTENT-노출': {
    type: 'viewed content',
    startedAt: '2021-10-27',
  },
} as const;

export function trackPredefinedEvent(
  eventKind: EventKind,
  properties?: Object
) {
  const { type, name, startedAt, from } = EVENT_LIST[eventKind];

  if (from) {
    return track(`${type} in ${from}`, {
      name: name ?? type,
      startedAt,
      ...properties,
    });
  } else {
    return track(type, {
      name: name ?? type,
      startedAt,
      ...properties,
    });
  }
}
