function isValidRegistrationNumber(value: string): boolean {
  return /^\d{10}$/.test(value);
}

function formatRegistrationNumber(
  value: string,
  { mask }: { mask?: boolean } = {}
): string {
  if (!isValidRegistrationNumber(value)) {
    throw Error(
      `Given value ${value} is not a valid registration number format.`
    );
  }
  return value.replace(
    /(\d{3})(\d{2})(\d{5})/,
    mask ? '$1-$2-*****' : '$1-$2-$3'
  );
}

const CORPORATION_CODES = ['81', '82', '83', '84', '85', '86', '87', '88'];

function isCorporationRegistrationNumber(value: string) {
  return CORPORATION_CODES.includes(value.slice(3, 5));
}

export {
  isValidRegistrationNumber,
  formatRegistrationNumber,
  isCorporationRegistrationNumber,
};
