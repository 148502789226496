import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Business, BusinessContext } from 'contexts/BusinessContext';

export function useCurrentBusinessId(): string {
  const { businessId } = useParams<{ businessId?: string }>();

  if (!businessId) {
    throw new Error('Cannot find businessId from the current location params.');
  }

  return businessId;
}

export function useCurrentBusinessIdWithSilent() {
  const { businessId } = useParams<{ businessId?: string }>();

  return businessId;
}

export function useCurrentBusiness(): Business {
  const { currentBusiness } = useContext(BusinessContext);

  if (!currentBusiness) {
    throw Error('CurrentBusiness is not set.');
  }

  return currentBusiness;
}

export function withCurrentBusiness(Component: React.ComponentType<any>) {
  return function WrapperComponent(props: any) {
    const currentBusiness = useCurrentBusiness();

    return <Component {...props} currentBusiness={currentBusiness} />;
  };
}
