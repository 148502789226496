import { parseCookies, setCookie } from 'nookies';

export interface CookieSerializeOptions {
  domain?: string | undefined;
  encode?(value: string): string;
  expires?: Date | undefined;
  httpOnly?: boolean | undefined;
  maxAge?: number | undefined;
  path?: string | undefined;
  sameSite?: true | false | 'lax' | 'strict' | 'none' | undefined;
  secure?: boolean | undefined;
}

const defaultDomain =
  process.env.NODE_ENV === 'development' ? 'localhost' : '.cashnote.kr';

const cookie = {
  get: (key: string) => {
    const cookies = parseCookies();
    return cookies[key];
  },
  set: (key: string, value: string, options?: CookieSerializeOptions) => {
    setCookie(null, key, value, {
      domain: defaultDomain,
      path: '/',
      secure: true,
      sameSite: 'strict',
      ...options,
    });
  },
};

export default cookie;
