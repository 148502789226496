import 'react-app-polyfill/ie9';
import 'core-js/features/string';
import 'core-js/features/array';
import 'core-js/features/number';
import 'core-js/features/object';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/weak-map';
import 'promise.prototype.finally/auto';
import 'element-closest-polyfill';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { initializeApp2WebInterface, web2app } from '@kcd/app-interface';
import { initializeTrackerInterface, page } from '@kcd/event-tracker';
import { FeatherConfig } from '@kcd/feather';

import apolloClient from 'graphql/apolloClient';
import browserHistory from 'browserHistory';
import configureStore from 'store/configureStore';
import { LastLocationProvider } from 'contexts/LastLocationContext';
import { isAppSchemeAvailable, openApp } from 'utils/appUrlScheme';

import Root from 'Root';
import ErrorBoundary from 'ErrorBoundary';
import ThemeProvider from 'ThemeProvider';

import 'moment/locale/ko';
import './SpoqaHanSans-kr.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import '@kcd/feather/dist/feather.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initializeDatadogLogs, initializeDatadogRum } from 'libs/datadog';
import { createInstance, HackleProvider } from '@hackler/react-sdk';
import { initializeFontScale } from 'utils/appWebView/fontScale';

initializeApp2WebInterface();
initializeFontScale();

try {
  if (isAppSchemeAvailable(window.location)) {
    openApp();
  }
} catch (e) {
  // unexpected url, do nothing
}

moment.locale('ko-KR');
registerLocale('ko', ko);
setDefaultLocale('ko');

if (process.env.NODE_ENV !== 'development') {
  initializeTrackerInterface({
    greenfinchConfig: {
      writeKey: process.env.REACT_APP_GREENFINCH_WRITE_KEY as string,
      serviceName: 'kcd_cashnote',
      debug: process.env.REACT_APP_DEPLOY_ENV !== 'production',
    },
    analyticsConfig: {
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY as string,
      debug: process.env.REACT_APP_DEPLOY_ENV !== 'production',
    },
  });
}
if (process.env.REACT_APP_DEPLOY_ENV === 'production') {
  initializeDatadogLogs();
  initializeDatadogRum();
}

if (window.FontFace?.prototype.hasOwnProperty('display')) {
  document.body.classList.add('spoqa-han-sans-loaded');
} else {
  import('fontfaceobserver').then(({ default: FontFaceObserver }) => {
    Promise.all(
      [400, 700].map((weight) =>
        new FontFaceObserver('Spoqa Han Sans', { weight }).load(null, 1000 * 15)
      )
    ).then(() => {
      document.body.classList.add('spoqa-han-sans-loaded');
    });
  });
}

const store = configureStore({});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      // 에러시 600ms 후 재시도, 이후 연속 실패마다 재시도 간격 2배로 늘림
      retryDelay: (attemptIndex) => 600 * 2 ** attemptIndex,
    },
  },
});

export const hackleClient = createInstance(
  process.env.REACT_APP_HACKLE_SDK_KEY as string
);

ReactDOM.render(
  <ErrorBoundary>
    <ApolloHooksProvider client={apolloClient}>
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Router history={browserHistory}>
              <LastLocationProvider>
                <ThemeProvider>
                  <FeatherConfig
                    linkOpener={(url: string) =>
                      web2app.openModal({
                        url,
                        fallback: () => window.open(url),
                      })
                    }
                  >
                    <HackleProvider hackleClient={hackleClient}>
                      <Root />
                    </HackleProvider>
                  </FeatherConfig>
                </ThemeProvider>
              </LastLocationProvider>
            </Router>
          </Provider>
        </QueryClientProvider>
      </ApolloProvider>
    </ApolloHooksProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'production') {
  browserHistory.listen(() => {
    page();
  });
}
