import { web2app } from '@kcd/app-interface';
import { track } from 'utils/trackEvent';

import { APPS_HOST, V2_HOST, withHostnameUrl } from './urlMap';
import connectCookieService from 'domains/connect/connectCookieService';

let REFRESH_MODE: 'all' | 'webview' | 'dashboard' | undefined;

export function setCloseModalRefreshMode(mode: typeof REFRESH_MODE) {
  REFRESH_MODE = mode;
}

export function closeCurrentModal() {
  if (connectCookieService.isPosPlatform()) {
    window.close();
    return;
  }
  web2app.closeCurrentModal({
    refresh: REFRESH_MODE,
    fallback: () => {
      window.close(); // 닫을 수 있다면 닫고, 첫 페이지라서 돌아갈 곳이 없다면 홈으로.
      setTimeout(() => window.location.replace(APPS_HOST), 200);
    },
  });
}

export function openModal(url: string) {
  web2app.openModal({ url, fallback: () => window.open(url) });
}

export function openPathModal(path: string) {
  openModal(withHostnameUrl(path));
}

export function openV2Url(url: string) {
  openModal(`${V2_HOST}/${url}`);
}

export function openExternalBrowser(url: string) {
  web2app.openExternalBrowser({ url, fallback: () => window.open(url) });
}

type PathcodeAuthCommonParams = {
  transactionID?: string;
  title?: string;
  description?: string;
};

type RequestAndRegisterPasscodeAuthParams = {
  onSuccess: () => void;
  onCancel: () => void;
  requestParams?: PathcodeAuthCommonParams;
  registerParams?: PathcodeAuthCommonParams;
  from: string;
};

export function requestAndRegisterPasscodeAuth({
  onSuccess,
  onCancel,
  requestParams,
  registerParams,
  from,
}: RequestAndRegisterPasscodeAuthParams) {
  track(`viewed passcodeAuth in ${from}`, { type: '요청' });
  web2app.requestPasscodeAuth({
    ...requestParams,
    callback: async (status) => {
      switch (status) {
        case 'VERIFIED': {
          onSuccess();
          return;
        }
        case 'CANCEL': {
          onCancel();
          return;
        }
        case 'NOT_INITIALIZED': {
          track(`viewed passcodeAuth in ${from}`, { type: '등록' });
          web2app.registerPasscodeAuth({
            ...registerParams,
            callback: (status) => {
              switch (status) {
                case 'SUCCESS':
                  onSuccess();
                  return;
                case 'CANCEL':
                  onCancel();
              }
            },
          });
        }
      }
    },
  });
}

export const loadCertificateAny = web2app.loadCertificateAny;

export const askPinNumber = web2app.askPinNumber;

export * from '@kcd/app-interface';
