import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import { appVersion } from '@kcd/app-interface';

function getCurrentPageName() {
  return decodeURI(window.location.pathname).slice(1);
}

interface MetaData extends Record<string, unknown> {
  componentName?: string;
}

/**
 * 에러를 로깅합니다.
 */
export function logError(error: Error, meta?: MetaData) {
  const pageName = getCurrentPageName();
  const metaData = { ...meta, pageName, appVersion: appVersion() };

  if (axios.isAxiosError(error) && error.response) {
    console.log('axios error');
    const { status, config } = error.response;
    const axiosMeta = {
      ...metaData,
      request: { url: config.url, method: config.method },
      response: { status, data: error.response.data as unknown },
    };
    datadogLogs.logger.error(`AxiosError: ${error.message}`, axiosMeta, error);
    return;
  }
  datadogLogs.logger.error(`${error.name}: ${error.message}`, metaData, error);
}

export function logMessage(message: string, meta?: MetaData) {
  const pageName = getCurrentPageName();
  const metaData = { ...meta, pageName, appVersion: appVersion() };

  datadogLogs.logger.info(message, metaData);
}
