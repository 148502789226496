import gql from 'graphql-tag.macro';

export default gql`
  mutation deactivateAccount($password: String!) {
    deactivateAccount(input: { password: $password }) {
      errors {
        field
        messages
      }
    }
  }
`;
