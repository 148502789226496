import React, { Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import AutoLogin from 'components/AutoLogin';
import PrivateRoute from 'components/PrivateRoute';

import lazy from 'libs/lazy';

import { isAppV2 } from 'utils/userAgent';
import { businessIndexPath } from 'ducks/auth';

const WebApp = lazy(() => import('components/WebApp'));
const MobileApp = lazy(() => import('components/MobileApp'));
const MobileApp2 = lazy(() => import('merlin/MobileApp'));
const Logout = lazy(() => import('components/Logout'));
const AppModal = lazy(() => import('pages/AppModal'));
const ConnectFranchise = lazy(() => import('pages/Connect/Franchise'));

export const RedirectTo = (
  pathname: string,
  { search }: { search?: string } = {}
) => () => {
  window.location.replace(`${pathname}${search ?? ''}`);
  return <></>;
};

const Root = () => {
  const { search } = useLocation();
  return (
    // @ts-ignore
    <AutoLogin>
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/logout" component={Logout} />
          <PrivateRoute
            path="/m"
            component={isAppV2() ? MobileApp2 : MobileApp}
          />
          <Route path="/app-modal" component={AppModal} />
          <Route
            exact
            path="/connect/franchise/:branchId"
            component={ConnectFranchise}
          />
          <Route exact path="/" component={RedirectTo(businessIndexPath())} />
          <Route path="/시작" component={RedirectTo('/auth', { search })} />
          <Route
            path="/신용점수조회"
            component={RedirectTo('/finance/credit-score', { search })}
          />
          <Route component={WebApp} />
        </Switch>
      </Suspense>
    </AutoLogin>
  );
};

export default Root;
