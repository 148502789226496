import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Route from 'components/Route';

import { AccountProvider } from 'contexts/AccountContext';
import useAuthToken from 'hooks/useAuthToken';

import { redirectAfterLogin } from 'ducks/auth';
import paths from 'paths';
import useAuthRefresh from 'hooks/useAuthRefresh';
import {
  convertToBookUrl,
  convertToAppOnlyUrl,
  convertToV2OnlyUrl,
} from 'merlin/utils/appWebView';
import connectCookieService from 'domains/connect/connectCookieService';
import {
  useDatadogLogsWithBusinessId,
  useDatadogRumWithBusinessId,
} from 'libs/datadog';

export const redirectAfterLoginSession = {
  set: (path) => sessionStorage.setItem('redirectAfterLogin', path),
  get: () => sessionStorage.getItem('redirectAfterLogin'),
  clear: () => sessionStorage.removeItem('redirectAfterLogin'),
};

function needsRedirect() {
  return connectCookieService.isFromConnectService();
}

const PrivateRoute = (props) => {
  const dispatch = useDispatch();
  const loggedIn = !!useAuthToken();
  const { pathname, search } = props.location;
  const startRefresh = useAuthRefresh();

  useDatadogRumWithBusinessId();
  useDatadogLogsWithBusinessId();

  useEffect(() => {
    if (loggedIn) {
      startRefresh();
    } else {
      /**
       * 커넥트에서 케스트렐을 처음 열 경우 토큰이 없어서 auth 서비스에 갔다가 다시 돌아옴.
       * 이 때 원래 가려던 페이지로 이동하기 위해 페이지 저장.
       */
      needsRedirect() && redirectAfterLoginSession.set(`${pathname}${search}`);

      // redux를 이용한 redirectAfterLogin은 auth 서비스 이후로는 동작하지 않을것으로 보임
      // auth 서비스에 가는 순간 redux store는 리셋되기 때문
      // 삭제하고 redirectAfterLoginSession 을 활용하는게 좋을 듯 하나 side effect가 파악되지 않아서 주석 작성 시점에는 일단 보류
      dispatch(redirectAfterLogin(`${pathname}${search}`));

      // 토큰이 없어서 튕길 때는 auth에서 로그인 이후 returnUrl을 통해 원래 가려던 곳으로 돌아올 수 있도록 세팅 + search param 유지
      paths.replace(
        paths.시작.index(
          `?returnUrl=${encodeURIComponent(window.location.href)}`
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && needsRedirect() && redirectAfterLoginSession.get()) {
      paths.replace(redirectAfterLoginSession.get());
      redirectAfterLoginSession.clear();
    }
  }, [loggedIn]);

  const v2Url = convertToV2OnlyUrl(); // TODO: convertToV2Url로 교체하는 것 고려해보기.
  const convertUrl = convertToBookUrl();
  const appOnlyUrl = convertToAppOnlyUrl();

  if (v2Url) {
    window.location.href = v2Url;
    return null;
  }
  if (convertUrl) {
    window.location.href = convertUrl;
    return null;
  }
  if (appOnlyUrl) {
    window.location.href = appOnlyUrl;
    return null;
  }

  return loggedIn ? (
    <AccountProvider>
      <Route {...props} />
    </AccountProvider>
  ) : null;
};

export default PrivateRoute;
