const omitEmptyValues = (obj) => {
  const clone = { ...obj };
  Object.keys(clone).forEach((key) => {
    if (clone[key] === undefined || clone[key] === null) {
      delete clone[key];
    }
  });

  return clone;
};

const isEmpty = (obj) => Object.keys(obj).length === 0;

const transformValues = (obj, fn) =>
  Object.keys(obj).reduce((result, key) => {
    result[key] = fn(obj[key]);
    return result;
  }, {});

export { omitEmptyValues, isEmpty, transformValues };
