import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import packageJson from '../../package.json';
import { useCurrentBusinessIdWithSilent } from 'hooks/useCurrentBusiness';
import { datadogLogs } from '@datadog/browser-logs';
import { useAccount } from 'contexts/AccountContext';

function isWhiteListUrl(url: string) {
  return [
    /^https:\/\/(dev-|staging-)?api\.cashnote\.kr/,
    /^https:\/\/(dev-|staging-)?business-tools-api\.cashnote\.kr/,
  ].some((regexr) => regexr.test(url));
}
/**
 * 수집하지 않을 에러 메시지를 나열합니다. 대소문자는 구분하지 않습니다.
 * 이력 파악을 위해 ignore 하는 이유도 함께 기록해주세요.
 */
function isIgnoreError(fullMessage: string) {
  return [
    'Could not resolve to a Business with the ID', // 예전부터 ignore 중이던 에러
    'Loading chunk 996 failed', // sentry에서 발생하는 에러인데 정상동작하는 것으로 보여 ignore
    '취소됨', // request cancel 될 때 발생하는 에러로 딱히 할 수 있는게 없으므로 ignore
  ].some((rule) => fullMessage.toLowerCase().includes(rule.toLowerCase()));
}

/**
 * 공식문서 : https://docs.datadoghq.com/real_user_monitoring/browser/
 * 설치 가이드 : https://koreacreditdata.atlassian.net/wiki/spaces/ENG/pages/3472786814/Real-User+Monitoring
 */
export function initializeDatadogRum() {
  datadogRum.init({
    applicationId: '53506945-e25a-4542-aabe-381ea74a1102',
    clientToken: 'pub3372d37850e405863762daba477a5255',
    site: 'datadoghq.com',
    env: process.env.REACT_APP_DEPLOY_ENV,
    service: 'business-tools-web-kestrel',
    version: packageJson.version,
    sessionSampleRate: 5,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: false,
    defaultPrivacyLevel: 'mask',
    allowedTracingUrls: [isWhiteListUrl],
    beforeSend: (event) => {
      if (event.type === 'error' && isIgnoreError(event.error.message)) {
        return false;
      }
      return true;
    },
  });
  datadogRum.startSessionReplayRecording();
}

export function useDatadogRumWithBusinessId() {
  const businessId = useCurrentBusinessIdWithSilent();

  useEffect(() => {
    if (!businessId) return;

    datadogRum.setUserProperty('businessId', businessId);
  }, [businessId]);
}

export function initializeDatadogLogs() {
  datadogLogs.init({
    clientToken: 'pub3372d37850e405863762daba477a5255',
    site: 'datadoghq.com',
    env: process.env.REACT_APP_DEPLOY_ENV,
    service: 'business-tools-web-kestrel',
    version: packageJson.version,
    forwardErrorsToLogs: false, // 명시적으로 수집한 에러만 로그를 남깁니다.
    sessionSampleRate: 100,
    telemetrySampleRate: 0,
    beforeSend: (log) => {
      if (isIgnoreError(log.message)) {
        return false;
      }
      return true;
    },
  });
}

export function useDatadogLogsWithBusinessId() {
  const { trackingId } = useAccount() ?? {};
  const businessId = useCurrentBusinessIdWithSilent();

  useEffect(() => {
    if (!trackingId) return;
    datadogLogs.setUserProperty('trackingId', trackingId);

    if (!businessId) return;
    datadogLogs.setUserProperty('businessId', businessId);
  }, [businessId, trackingId]);
}

export * from '@datadog/browser-rum';
export * from '@datadog/browser-logs';
