import { useEffect } from 'react';

export const FORCE_HIDE_ZENDESK_PATHS = [
  '/m/businesses/:id/settings',
  '/m/businesses/:id/detailed-settings',
  '/m/businesses/:id/integrations/crefia/confirmation',
  '/m/businesses/:id/integrations/crefia/realtime',
  '/m/businesses/:id/integrations/pos/:step?',
  '/m/businesses/:id/integrations/market-analysis/:step?',
];

export const FORCE_SHOW_ZENDESK_PATHS = [];

export function prefillZendeskWidget(content: {
  name?: { value: string; readOnly?: boolean };
  email?: { value: string; readOnly?: boolean };
  phone?: { value: string; readOnly?: boolean };
}) {
  if (!window.zE) return;

  window.zE('webWidget', 'prefill', content);
}

export function openZendeskWidget(
  content?: Parameters<typeof prefillZendeskWidget>[0]
) {
  if (!window.zE) return;

  if (content) {
    prefillZendeskWidget(content);
  }

  window.zE('webWidget', 'open');
}

const useZendesk = ({
  show = true,
  offset = null,
}: {
  show?: boolean;
  offset?: number | null;
} = {}) => {
  useEffect(() => {
    if (!window.zE) return;

    if (show) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            mobile: {
              vertical: offset || 0,
            },
          },
        },
      });
    } else {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            mobile: {
              vertical: -100,
            },
          },
        },
      });
    }
  }, [show, offset]);
};

export default useZendesk;
