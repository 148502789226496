import jwtDecode from 'jwt-decode';
import apolloClient from 'graphql/apolloClient';
import storage from 'local-storage-fallback';

import paths from 'paths';

import { isMobile } from 'utils/userAgent';
import { omitEmptyValues, isEmpty } from 'utils/object';
import { track, identifyUser, clearUser } from 'utils/trackEvent';
import { logError } from 'utils/errorLogger';

const LOAD = 'auth/LOAD';
const LOAD_FAIL = 'auth/LOAD_FAIL';
const REDIRECT_AFTER_LOGIN = 'auth/REDIRECT_AFTER_LOGIN';
export const REMOVE_AUTH = 'auth/REMOVE_AUTH';
const SET_ACCESS_TOKEN = 'auth/SET_ACCESS_TOKEN';

const token = storage.getItem('token');

if (!!token) {
  try {
    const { exp } = jwtDecode(token);
    if (new Date().getTime() / 1000 > exp) {
      storage.removeItem('token');
    }
  } catch (ex) {
    storage.removeItem('token');
  }
}

const initialState = {
  token: storage.getItem('token'),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        token: action.token,
      };
    case LOAD_FAIL:
      return {
        ...state,
        errors: action.errors,
      };
    case REDIRECT_AFTER_LOGIN:
      return {
        ...state,
        redirectAfterLogin: action.redirect,
      };
    case REMOVE_AUTH:
      return {
        token: null,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    default:
      return state;
  }
}

export const businessIndexPath = () =>
  isMobile() ? paths.mobile.businesses.index() : paths.businesses.index();

export function loadAuth(token) {
  storage.setItem('token', token);

  return {
    type: LOAD,
    token,
  };
}

export function setAccessToken(accessToken) {
  return {
    type: SET_ACCESS_TOKEN,
    accessToken,
  };
}

export function removeAuth() {
  clearUser();

  storage.removeItem('token');
  apolloClient.clearStore();

  return { type: REMOVE_AUTH };
}

export function getValidReturnUrl() {
  const returnUrl = new URLSearchParams(window.location.search).get(
    'returnUrl'
  );
  const isCashnoteService = /^https?:\/\/.+\.cashnote\.kr/.test(returnUrl);
  if (!isCashnoteService) {
    return;
  }

  return returnUrl;
}

export function signupSuccess(payload, trackEvent, trackingCodes = {}) {
  return (dispatch, getState) => {
    try {
      const { id, trackingId, realName, name, phoneNumber } = payload.user;

      identifyUser(trackingId, {
        publicUserId: id,
        userName: phoneNumber,
        name: realName ?? name,
        createdAt: new Date().toISOString(),
        alias: true,
      });

      if (trackEvent) {
        const codes = omitEmptyValues(trackingCodes);
        if (isEmpty(codes)) {
          track('Signed Up');
        } else {
          track('Signed Up', codes);
        }
      }

      const returnUrl = getValidReturnUrl();
      if (returnUrl) {
        window.location.href = returnUrl;
        return;
      }

      const token = payload.jwt;

      dispatch(loadAuth(token));
      dispatch(setAccessToken(null));

      setTimeout(() => {
        const redirect = getState().auth.redirectAfterLogin;
        if (payload.user.businesses.totalCount > 0) {
          paths.replace(redirect || businessIndexPath());
        } else {
          paths.replace(paths.businesses.사업장등록({ returnStep: '시작' }));
        }
      }, 300);
    } catch (e) {
      logError(e);
    }
  };
}

export function loginSuccess(payload) {
  return (dispatch, getState) => {
    try {
      const { id, trackingId, realName, name, phoneNumber } = payload.user;

      identifyUser(trackingId, {
        publicUserId: id,
        userName: phoneNumber,
        name: realName ?? name,
      });

      const returnUrl = getValidReturnUrl();
      if (returnUrl) {
        window.location.href = returnUrl;
        return;
      }

      const redirect = getState().auth.redirectAfterLogin;
      dispatch(setAccessToken(null));
      dispatch(loadAuth(payload.jwt));

      paths.replace(redirect || businessIndexPath());
    } catch (e) {
      logError(e);
    }
  };
}

export function redirectAfterLogin(redirect) {
  return {
    type: REDIRECT_AFTER_LOGIN,
    redirect,
  };
}

export function refresh(data) {
  return (dispatch) => {
    switch (data.refreshJwt.result.__typename) {
      case 'RefreshJwtSuccess':
        const refreshedToken = data.refreshJwt.result.jwt;

        const {
          id,
          trackingId,
          realName,
          name,
          phoneNumber,
        } = data.refreshJwt.result.user;

        identifyUser(trackingId, {
          publicUserId: id,
          userName: phoneNumber,
          name: realName ?? name,
        });

        dispatch(loadAuth(refreshedToken));
        return;

      default:
        dispatch(removeAuth());
        dispatch(
          redirectAfterLogin(
            `${window.location.pathname}${window.location.search}`
          )
        );
        paths.replace(paths.시작.index(window.location.search));
    }
  };
}

export function logout() {
  return (dispatch) => {
    dispatch(removeAuth());
  };
}
