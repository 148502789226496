import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { ApolloLink, Observable } from 'apollo-link';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import storage from 'local-storage-fallback';
import { trackAPICall } from '@kcd/event-tracker';

import {
  fieldNotFoundError,
  businessNotFoundError,
  sudoExpiredError,
  customCacheKey,
  knownPhoenixError,
  knownSnowdonError,
} from 'utils/graphql';

import { typeDefs, resolvers } from './clientSchema';
import introspectionQueryResultData from './fragmentTypes.json';
import { PHOENIX_TARGET } from './usePhoenix';
import { logMessage } from 'utils/errorLogger';

const isQuery = (operation) =>
  operation.query.definitions[0].operation === 'query';

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors) {
    const logMsg = (message, logTopLevel = true) =>
      logMessage(message, {
        graphqlOperation: {
          name: operation.operationName,
          variables: isQuery(operation) ? operation.variables : undefined,
        },
        errorMessage: logTopLevel ? graphQLErrors[0].message : message,
      });

    if (
      knownPhoenixError(graphQLErrors, operation) ||
      knownSnowdonError(graphQLErrors)
    ) {
      return;
    }

    if (sudoExpiredError(graphQLErrors)) {
      logMsg('GraphQL Error: Sudo JWT expired');
    } else if (fieldNotFoundError(graphQLErrors)) {
      logMsg('GraphQL Error: JWT expired');
    } else if (businessNotFoundError(graphQLErrors)) {
      logMsg('GraphQL Error: Business Not Found');
    } else {
      graphQLErrors.forEach((graphQLError) => {
        logMsg(graphQLError.message, false);
      });
    }
  }
});

const request = (operation) => {
  const token = storage.getItem('token');
  const { headers } = operation.getContext();

  operation.setContext({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const queryLoggerLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    trackAPICall({
      type: 'GraphQL',
      operationName: operation.operationName,
      apiTarget: operation.getContext().target || 'SNOWDON',
    });

    return data;
  });
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  freezeResults: true,
  dataIdFromObject: (object) =>
    customCacheKey(object) || defaultDataIdFromObject(object),
});

const client = new ApolloClient({
  assumeImmutableResults: true,
  link: ApolloLink.from([
    new RetryLink({
      delay: {
        initial: 600,
      },
      attempts: {
        max: 3,
        retryIf: (error, operation) => {
          const operationIsLogin = operation.operationName.match(/login/);
          return !!error && (operationIsLogin || isQuery(operation));
        },
      },
    }),
    errorLink,
    queryLoggerLink,
    requestLink,
  ]).split(
    (operation) => operation.getContext().target === PHOENIX_TARGET,
    new HttpLink({
      uri: process.env.REACT_APP_PHOENIX_GRAPHQL_API_ENDPOINT,
    }),
    new HttpLink({
      credentials: 'include',
      uri: process.env.REACT_APP_GRAPHQL_API_ENDPOINT,
    })
  ),
  cache,
  typeDefs,
  resolvers,
});

export default client;
