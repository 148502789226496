import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parse as parseQueryString } from 'query-string';
import { withApollo } from 'react-apollo';

import useAuthToken from 'hooks/useAuthToken';

import loginWithToken from 'graphql/mutations/loginWithToken.graphql';

import { loadAuth } from 'ducks/auth';
import paths from 'paths';

const AutoLogin = ({ client, children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = !!useAuthToken() || location.pathname === 'logout';

  const parsedSearch = parseQueryString(useLocation().search);

  const [loginToken] = useState(parsedSearch.login_token);
  const [authenticating, setAuthenticating] = useState(
    isLoggedIn ? false : !!loginToken
  );

  useEffect(() => {
    if (loginToken) {
      client
        .mutate({
          mutation: loginWithToken,
          variables: {
            token: loginToken,
          },
        })
        .then(
          ({
            data: {
              loginWithToken: { jwt },
            },
          }) => {
            if (jwt) {
              dispatch(loadAuth(jwt));
            }
            paths.removeSearchKey('login_token');
            setAuthenticating(false);
          }
        )
        .catch(() => {
          paths.removeSearchKey('login_token');
          setAuthenticating(false);
        });
    }
  }, [dispatch, loginToken, client]);

  return authenticating ? null : children;
};

export default withApollo(AutoLogin);
