import gql from 'graphql-tag.macro';

export default gql`
  mutation loginWithToken($token: String!) {
    loginWithToken(input: { token: $token }) {
      jwt
      errors {
        field
        messages
      }
    }
  }
`;
