import { lazy as reactLazy } from 'react';

const RETRY_ERROR_TYPE = 'timeout';

function retry(
  fn: () => Promise<any>,
  retriesLeft = 5,
  interval = 0
): Promise<any> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (error.type !== RETRY_ERROR_TYPE || retriesLeft === 1) {
          reject(error);
          return;
        }
        setTimeout(() => {
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default function lazy(fn: () => Promise<any>) {
  return reactLazy(() => retry(fn));
}
