import gql from 'graphql-tag.macro';

export default gql`
  mutation updateAccount($name: String, $password: String) {
    updateAccount(input: { name: $name, password: $password }) {
      account {
        id
        login
        name
        phoneNumber
        hasPassword
        inviteCode
        hasKakaoProfile
        trialStartedAt
      }
      errors {
        field
        messages
      }
    }
  }
`;
