import gql from 'graphql-tag.macro';

export default gql`
  query getBusiness($businessId: ID!) {
    business(businessId: $businessId) {
      id
      name
      registrationNumber
      subscription {
        paid
      }
      role
      representativeRole
      representativeVerified
      hasSalesInvoices
      featureFlags
      incorporated
    }
  }
`;
